@import "toast.animation";
@import "toast.color";

.dxbl-toast-portal {
    --dxbl-toast-bound-margin: #{$dx-toast-bound-margin};
    --dxbl-toast-bound-padding: #{$dx-toast-bound-padding};

    $render-modes: (
        "primary",
        "info",
        "success",
        "warning",
        "danger"
    );

    $theme-modes: (
        "auto",
        "light",
        "dark",
        "saturated",
        "pastel"
    );

    display: block;
    position: absolute;
    padding-right: var(--dxbl-toast-bound-padding);

    &.dxbl-toast-fixed {
        position: fixed;
    }

    &.dxbl-toast-align-top {
        top: 0;
    }

    &.dxbl-toast-align-bottom {
        bottom: 0;
    }

    &.dxbl-toast-align-left {
        left: 0;
        margin-left: var(--dxbl-toast-bound-margin);
    }

    &.dxbl-toast-align-center {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    &.dxbl-toast-align-right {
        right: 0;
    }

    .dxbl-toast-hide-all-btn {
        margin-top: 5px;
        width: 100%;
    }

    .dxbl-toast {
        --dxbl-toast-border-radius: #{$dx-toast-border-radius}; // #{getToastProp("border-radius", "medium")};
        --dxbl-toast-border-width: #{$dx-toast-border-width}; // #{getToastProp("border-width", "medium")};
        --dxbl-toast-box-shadow: #{$dx-toast-box-shadow}; // #{getToastProp("box-shadow", "medium")};
        --dxbl-toast-margin-top: #{$dx-toast-margin-top};
        --dxbl-toast-accent-line-width: #{$dx-toast-accent-ling-width};
        --dxbl-toast-header-font-size: #{$dx-toast-header-font-size};
        --dxbl-toast-header-font-color: #{$dx-toast-font-color-dark};
        --dxbl-toast-header-font-weight: #{$dx-toast-header-font-weight};
        --dxbl-toast-header-gap: #{$dx-toast-header-gap};
        --dxbl-toast-text-font-size: #{$dx-toast-text-font-size};
        --dxbl-toast-text-font-color: #{$dx-toast-font-color-dark};
        --dxbl-toast-text-font-weight: #{$dx-toast-text-font-weight};
        --dxbl-toast-icon-top-padding: #{$dx-toast-icon-top-padding};
        --dxbl-toast-text-content-top-padding: #{$dx-toast-text-content-top-padding};
        --dxbl-toast-content-gap: #{$dx-toast-content-gap};
        --dxbl-toast-text-content-gap: #{$dx-toast-text-content-gap};
        --dxbl-toast-template-gap: #{$dx-toast-template-gap};
        --dxbl-toast-template-margin-bottom: #{$dx-toast-template-margin-bottom};
        --dxbl-toast-header-line-height: #{$dx-toast-text-line-height};
        --dxbl-toast-text-line-height: #{$dx-toast-text-line-height};
        --dxbl-toast-body-padding: #{$dx-toast-body-padding};
        --dxbl-toast-close-button-color: #{$dx-toast-close-btn-color-dark};

        display: flex;
        margin: var(--dxbl-toast-margin-top) 0;
        color: var(--dxbl-toast-text-font-color);
        border-radius: var(--dxbl-toast-border-radius);
        background-color: var(--dxbl-toast-background-color);
        box-shadow: var(--dxbl-toast-box-shadow);
        animation: var(--dxbl-toast-animation-type);
        animation-duration: var(--dxbl-toast-animation-duration);

        &.dxbl-sm {
            --dxbl-toast-box-shadow: #{$dx-toast-box-shadow}; // #{getToastProp("box-shadow", "small")};
            --dxbl-toast-icon-top-padding: #{$dx-toast-icon-top-padding-sm};
            --dxbl-toast-content-gap: #{$dx-toast-content-gap-sm};
            --dxbl-toast-header-gap: #{$dx-toast-header-gap-sm};
            --dxbl-toast-text-content-gap: #{$dx-toast-text-content-gap-sm};
            --dxbl-toast-template-gap: #{$dx-toast-template-gap-sm};
            --dxbl-toast-body-padding: #{$dx-toast-body-padding-sm};
            --dxbl-toast-header-font-size: #{$dx-toast-header-font-size-sm};
            --dxbl-toast-text-font-size: #{$dx-toast-text-font-size-sm};
            --dxbl-toast-header-line-height: #{$dx-toast-text-line-height-sm};
            --dxbl-toast-text-line-height: #{$dx-toast-text-line-height-sm};
        }

        &.dxbl-lg {
            --dxbl-toast-box-shadow: #{$dx-toast-box-shadow};
            --dxbl-toast-icon-top-padding: #{$dx-toast-icon-top-padding-lg};
            --dxbl-toast-content-gap: #{$dx-toast-content-gap-lg};
            --dxbl-toast-header-gap: #{$dx-toast-header-gap-lg};
            --dxbl-toast-text-content-gap: #{$dx-toast-text-content-gap-lg};
            --dxbl-toast-template-gap: #{$dx-toast-template-gap-lg};
            --dxbl-toast-body-padding: #{$dx-toast-body-padding-lg};
            --dxbl-toast-header-font-size: #{$dx-toast-header-font-size-lg};
            --dxbl-toast-text-font-size: #{$dx-toast-text-font-size-lg};
            --dxbl-toast-header-line-height: #{$dx-toast-text-line-height-lg};
            --dxbl-toast-text-line-height: #{$dx-toast-text-line-height-lg};
        }

        @mixin combine-theme-render($theme-value, $render-value) {
            &.dxbl-toast-theme-#{$theme-value}.dxbl-toast-render-#{$render-value} {
                --dxbl-toast-background-color: #{getToastBackground($theme-value, $render-value)};
                --dxbl-toast-accent-line-color: #{getAccentColor($render-value)};
                --dxbl-toast-front-color: #{getTextColor($theme-value, $render-value)};
                --dxbl-toast-header-font-color: var(--dxbl-toast-front-color);
                --dxbl-toast-text-font-color: var(--dxbl-toast-header-font-color);
                --dxbl-toast-icon-color: #{getIconColor($theme-value, $render-value)};
                --dxbl-toast-close-button-color: var(--dxbl-toast-front-color);
                --dxbl-toast-close-button-hover-color: #{getCloseButtonHoverColor($theme-value)};
                --dxbl-toast-close-button-hover-bg: #{getCloseButtonHoverBg($theme-value)};
            }
        }

        @each $theme-mode in $theme-modes {
            @each $render-value in $render-modes {
                @include combine-theme-render($theme-mode, $render-value);
            }
        }

        .dxbl-toast-accent-line {
            width: var(--dxbl-toast-accent-line-width);
            background-color: var(--dxbl-toast-accent-line-color);
            border-top-left-radius: var(--dxbl-toast-border-radius);
            border-bottom-left-radius: var(--dxbl-toast-border-radius);
            flex: 0 0 auto;
        }

        .dxbl-toast-body {
            display: flex;
            width: 100%;
            gap: var(--dxbl-toast-content-gap);
            padding: var(--dxbl-toast-body-padding);
        }

        .dxbl-toast-icon-column {
            padding: var(--dxbl-toast-icon-top-padding) 0 0 0;

            .dxbl-toast-icon {
                fill: var(--dxbl-toast-icon-color);
                vertical-align: unset;
            }
        }

        .dxbl-toast-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: var(--dxbl-toast-content-gap);
            overflow: hidden;

            .dxbl-toast-text-content {
                display: flex;
                flex-direction: column;
                padding: var(--dxbl-toast-text-content-top-padding) 0 0 0;
                gap: var(--dxbl-toast-text-content-gap);
                overflow: hidden;

                .dxbl-toast-header {
                    color: var(--dxbl-toast-header-font-color);
                    font-size: var(--dxbl-toast-header-font-size);
                    font-weight: var(--dxbl-toast-header-font-weight);
                    line-height: var(--dxbl-toast-header-line-height);
                }

                .dxbl-toast-text {
                    color: var(--dxbl-toast-text-font-color);
                    font-size: var(--dxbl-toast-text-font-size);
                    font-weight: var(--dxbl-toast-text-font-weight);
                    line-height: var(--dxbl-toast-text-line-height);
                    display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: var(--dxbl-toast-line-clamp);
                }
            }

            .dxbl-toast-template {
                display: flex;
                justify-content: flex-end;
                gap: var(--dxbl-toast-template-gap);
                margin-bottom: var(--dxbl-toast-template-margin-bottom);
            }
        }

        .dxbl-toast-close {
            .dxbl-toast-close-btn {
                --dxbl-btn-padding-x: #{$dx-toast-close-btn-padding-x};

                fill: var(--dxbl-toast-close-button-color);

                &:hover {
                    --dxbl-btn-hover-bg: var(--dxbl-toast-close-button-hover-bg);
                    --dxbl-toast-close-button-color: var(--dxbl-toast-close-button-hover-color);
                }
            }
        }
    }
}

.dxbl-toast {
    display: none;
}
