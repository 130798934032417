$ColorBlue: var(--bs-blue);
$ColorDark: var(--bs-dark);
$ColorIndigo: var(--bs-indigo);
$ColorPurple: var(--bs-purple);
$ColorPink: var(--bs-pink);
$ColorRed: var(--bs-red);
$ColorOrange: var(--bs-orange);
$ColorYellow: var(--bs-yellow);
$ColorGreen: var(--bs-green);
$ColorTeal: var(--bs-teal);
$ColorCyan: var(--bs-cyan);
$ColorGray: var(--bs-gray);
$ColorWhite: var(--bs-white);
$ColorPrimary: var(--bs-primary);
$ColorLight: var(--bs-light);

$ColorRedName: --bs-red;
$ColorGreenName: --bs-green;
