@import "mixins/common";

/* Scheduler main element */
.dxbl-scheduler {
    --dxbl-scheduler-font-family: #{$dx-scheduler-font-family};
    --dxbl-scheduler-font-size: #{$dx-scheduler-font-size};
    --dxbl-scheduler-background-color: #{$dx-scheduler-background-color};
    --dxbl-scheduler-border-color: #{$dx-scheduler-border-color};
    --dxbl-scheduler-border-style: #{$dx-scheduler-border-style};
    --dxbl-scheduler-border-width: #{$dx-scheduler-border-width};
    --dxbl-scheduler-border-radius: #{$dx-scheduler-border-radius};
    --dxbl-scheduler-hour-cell-width: 3.5em;
    --dxbl-scheduler-minute-cell-font-size-ratio: 0.75;

    user-select: none;
    display: flex;
    background-color: var(--dxbl-scheduler-background-color);
    border-radius: var(--dxbl-scheduler-border-radius);
    border: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
    font-family: var(--dxbl-scheduler-font-family);
    font-size: var(--dxbl-scheduler-font-size);

    .dxbl-sc-scroll-container > .dxbl-sc-vertical-view > tbody > tr {
        &:first-child {
            border-top-width: 0;
        }

        & > .dxbl-sc-gr-sep-vertical,
        &:nth-last-of-type(2) > .dxbl-sc-hour-cell,
        &:last-child,
        &:last-child > td {
            border-bottom-width: 0;
        }

        &:nth-child(2n+1) {
            border-bottom-width: 0;

            & > .dxbl-sc-minute-cell,
            & > .dxbl-sc-time-cell {
                border-bottom-width: 1px;
            }
        }

        &:nth-child(2n) {
            border-top-width: 0;
        }
    }

    .dxbl-sc-timecells-container {
        position: relative;

        & > div {
            position: relative;
            height: 100%;

            .dxbl-sc-time-marker-image {
                top: -6px;
            }
        }

        & .dxbl-sc-horizontal-view > tbody {
            & > tr.dxbl-sc-arm {
                border-width: 0;
            }

            & > tr:first-child,
            & > tr.dxbl-sc-arm + tr {
                border-top-width: 0;
            }

            & > tr:last-child {
                border-bottom-width: 0;

                & > td {
                    border-bottom-width: 0;
                }
            }
        }
    }

    .dxbl-sc-main-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    /* toolbar */
    .dxbl-sc-tb-wrapper {
        flex: 0 0 auto;
        padding: 0.75rem;
    }

    /* View */
    .dxbl-sc-horizontal-view {
        table-layout: fixed;
        margin-bottom: 0;

        &.dxbl-sc-table {
            border-width: 0;
        }

        & > tbody > tr > td {
            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .dxbl-sc-vertical-view {
        table-layout: fixed;

        &.dxbl-sc-table {
            border-width: 0;
        }

        & > tbody > tr > td {
            border-top: 0;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .dxbl-sc-table tr > td.dxbl-date-hr:nth-last-child(-2n+2),
    .dxbl-sc-table tr > td.dxbl-horizontal-rh:nth-last-child(-2n+2) {
        border-right: 0;
    }

    .dxbl-sc-all-day-area::before {
        content: ".";
        visibility: hidden;
    }

    .dxbl-sc-resource-hr {
        overflow: hidden;
    }

    .dxbl-v-resource-header {
        overflow: hidden;

        .dxbl-resource-header-content {
            display: inline-flex;
            height: 100%;
            justify-content: center;
            align-items: center;

            & > span {
                transform: rotate(180deg);
                writing-mode: vertical-rl;
            }
        }
    }

    .dxbl-scroll-viewer-vert-scroll-bar,
    .dxbl-scroll-viewer-hor-scroll-bar {
        z-index: 200;
    }

    .dxbl-sc-today {
        &.dxbl-sc-time-cell,
        &.dxbl-sc-date-hr {
            position: relative;

            &::before {
                content: "";
                background-color: var(--dxbl-scheduler-primary-color);
                position: absolute;
                height: 4px;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    .dxbl-sc-gr-sep-vertical {
        width: 16px;
        padding: 0;
        border-bottom: transparent;
    }

    .dxbl-sc-minute-cell::before {
        content: ".";
        visibility: hidden;
    }

    .dxbl-sc-table tr > td {
        &.dxbl-sc-minute-cell {
            border-left: 0;
            width: var(--dxbl-scheduler-hour-cell-width);
            text-align: center;
            vertical-align: middle;
            font-size: calc(1em * var(--dxbl-scheduler-minute-cell-font-size-ratio));
            white-space: nowrap;
            position: sticky;
            left: calc(var(--dxbl-scheduler-hour-cell-width) * (1 / var(--dxbl-scheduler-minute-cell-font-size-ratio)));
            background-color: var(--dxbl-scheduler-background-color);
            z-index: 150;
        }

        &.dxbl-sc-hour-cell {
            border-right: 0;
            width: var(--dxbl-scheduler-hour-cell-width);
            text-align: right;
            white-space: nowrap;
            position: sticky;
            left: 0;
            background-color: var(--dxbl-scheduler-background-color);
            z-index: 160;
        }
    }

    .dxbl-hour-time-scale.dxbl-sc-table tr > td.dxbl-sc-hour-cell,
    .dxbl-sc-time-rulers-caption {
        width: 6.13em;
    }

    .dxbl-hour-time-scale.dxbl-sc-table tr > td.dxbl-sc-minute-cell {
        left: 6.13em;
    }

    .dxbl-sc-hour-min-text-compact {
        display: none;
    }

    .dxbl-sc-date-hr-wrapper {
        display: flex;
        align-items: baseline;
        overflow: hidden;
    }

    .dxbl-sc-date-hr-month,
    .dxbl-sc-date-hr-day {
        font-size: 1.25rem;
        margin-right: 0.2225rem;
    }

    .dxbl-sc-dayview {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .dxbl-sc-date-hr {
            padding-left: 0.4725rem;
        }

        .dxbl-sc-headers-scroll-area .dxbl-sc-headers-container {
            position: relative;

            & .dxbl-sc-table.dxbl-sc-horizontal-view {
                border-top: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
                border-bottom: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
            }
        }

        .dxbl-sc-time-rulers-caption {
            position: sticky;
            left: 0;
            background-color: var(--dxbl-scheduler-background-color);
            z-index: 150;
            border-bottom: transparent;
        }

        & > div {
            display: flex;

            &:last-child {
                height: 100%;
                overflow: hidden;
            }
        }
    }

    .dxbl-sc-hour-boundary {
        border-style: solid;
    }

    .dxbl-sc-time-marker-line {
        width: 100%;
        border-style: dashed;
        border-width: 1px 0 0;
        border-color: var(--dxbl-scheduler-primary-color);
    }

    .dxbl-sc-time-marker {
        display: flex;
        align-items: center;
        position: absolute;
    }

    .dxbl-sc-time-indicator {
        position: absolute;
    }

    .dxbl-sc-time-indicator-line {
        width: 100%;
        height: 2px;
        background-color: var(--dxbl-scheduler-primary-color);
    }

    .dxbl-sc-time-marker-image {
        height: 12px;
        width: 12px;
        border-radius: 100%;
        border: 2px solid var(--dxbl-scheduler-white-color);
        background-color: var(--dxbl-scheduler-primary-color);
        z-index: 100;
        flex-shrink: 0;
    }

    /* Timeline */
    .dxbl-sc-month {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        & .dxbl-sc-time-cell {
            height: 150px;
        }

        .dxbl-sc-headers {
            table-layout: fixed;
            margin-bottom: 0;

            & > tbody > tr > td {
                border-left-style: none;

                &:last-child {
                    border-right-style: none;
                }
            }
        }

        .dxbl-sc-headers-scroll-area .dxbl-sc-headers-container .dxbl-sc-table.dxbl-sc-headers {
            border-top: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
            border-bottom: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
        }

        .dxbl-sc-horizontal-view > tbody > tr:first-child > td {
            border-top-style: none;
        }

        & > div {
            display: flex;

            &:last-child {
                height: 100%;
                overflow: hidden;
            }
        }
    }

    .dxbl-sc-timeline {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        & > div {
            display: flex;
        }

        & > div:last-child {
            height: 100%;
            overflow: hidden;
        }

        .dxbl-sc-timescale {
            table-layout: fixed;
            margin-bottom: 0;
        }

        .dxbl-sc-horizontal-view > tbody > tr {
            &:first-child > td {
                padding: 0;
                border-style: none;
            }

            &:nth-child(2) > td {
                border-top-style: none;
            }

            &:not(:first-child) > td {
                height: 150px;
            }
        }

        .dxbl-sc-resource-headers {
            border-style: none;

            & > tbody > tr > td {
                border-left-style: none;
                border-bottom-style: none;
                height: 150px;
            }
        }

        .dxbl-sc-date-hr {
            & > div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .dxbl-sc-headers-container {
            table.dxbl-sc-timescale:nth-of-type(n + 2) > tbody > tr:first-child {
                border-top-width: 0;
            }

            & > div {
                position: relative;

                .dxbl-sc-time-marker-image {
                    bottom: -6px;
                }
            }

            .dxbl-sc-table.dxbl-sc-timescale {
                border-top: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);

                &:last-child {
                    border-bottom: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
                }
            }
        }

        .dxbl-sc-time-marker-image,
        .dxbl-sc-time-marker-line {
            display: none;
            position: absolute;
        }

        .dxbl-sc-time-marker-line {
            width: unset;
            top: 0;
            bottom: 0;
            border-width: 0 1px 0 0;
        }
    }

    .dxbl-sc-empty-cell {
        flex: 0 0 auto;
        width: 29px;

        .dxbl-sc-spacer {
            border-left-width: 0;
        }
    }

    .dxbl-sc-resource-headers {
        table-layout: fixed;
        margin-bottom: 0;
    }

    .dxbl-sc-headers-scroll-area {
        overflow: hidden;
        display: flex;
    }

    .dxbl-sc-resources-container,
    .dxbl-sc-headers-container {
        overflow: hidden;
    }

    .dxbl-sc-resources-scroll-area {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: 29px;
    }

    .dxbl-rh-text-wrapper {
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* Appointment */
    .dxbl-sc-apt {
        position: absolute;
        font-size: 0.9em;
        z-index: 8;
        user-select: none;

        & > div {
            position: relative;
            height: 100%;
        }

        &.dxbl-sc-vertical-apt {
            padding: 2px 2px 1px;
        }

        &.dxbl-sc-horizontal-apt {
            padding: 4px 4px 0;
        }

        .dxbl-apt-content {
            position: relative;
            overflow: hidden;
            height: 100%;
            display: flex;
        }

        .dxbl-apt-bg {
            opacity: 0.7;
            padding: 0;
            margin: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-radius: var(--dxbl-scheduler-border-radius);
            border-width: var(--dxbl-scheduler-border-width);
            border-style: var(--dxbl-scheduler-border-style);

            &.dxbl-apt-bg-default {
                background-color: var(--dxbl-scheduler-background-color);
                border-color: var(--dxbl-scheduler-border-color);
            }
        }

        &.dxbl-sc-apt-selected .dxbl-apt-bg {
            opacity: 1;
        }

        &.dxbl-sc-apt-disable {
            opacity: 0.5;
        }

        & .dxbl-sc-apt-dragged {
            box-shadow: 0 0.5rem 1rem rgba(0 0 0 / 15%);
        }

        .dxbl-top-handle,
        .dxbl-bottom-handle {
            cursor: n-resize;
            height: 5px;
            width: 100%;
            position: absolute;
            z-index: 10;
        }

        .dxbl-bottom-handle {
            bottom: 0;
        }

        .dxbl-left-handle,
        .dxbl-right-handle {
            cursor: e-resize;
            padding: 3px;
            z-index: 10;
            position: absolute;
            height: 100%;
        }

        .dxbl-left-handle {
            margin-right: -6px;
        }

        .dxbl-right-handle {
            margin-left: -6px;
            right: 0;
        }

        .dxbl-apt-status-container {
            background-color: var(--dxbl-scheduler-white-color);
            box-sizing: border-box;
            border-width: 0;
            position: relative;
            border-radius: var(--dxbl-scheduler-border-radius);
        }

        .dxbl-apt-status {
            width: 0.4em;
            height: 100%;
            display: block;
            position: relative;
            padding: 0;
            margin: 0;
            border-radius: var(--dxbl-scheduler-border-radius);
            border: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) rgba(34 34 34 / 12.5%);
        }

        .dxbl-sc-content-wrapper {
            padding: 0.2em 0.5em;
            width: 100%;
        }
    }

    .dxbl-sc-apt-container {
        height: 100%;
    }

    .dxbl-sc-apt-hovered .dxbl-apt-bg {
        opacity: 0.7;
    }

    .dxbl-sc-apt-time {
        font-size: 0.7rem;
        font-weight: 300;
        opacity: 0.8;
    }

    .dxbl-sc-apt-description {
        font-size: 0.8em;
        opacity: 0.65;
    }

    .dxbl-sc-apt-images-container {
        align-self: flex-end;
        padding-right: 0.2em;
    }

    .dxbl-sc-apt-compact .dxbl-sc-content-wrapper * {
        display: none;
    }

    .dxbl-sc-horizontal-apt .dxbl-sc-apt-compact .dxbl-sc-content-wrapper {
        min-height: 2em;
    }

    .dxbl-week-button {
        width: 35px;
        height: 35px;
        margin-bottom: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-flow: wrap-reverse;
    }

    .dxbl-sc-spacer {
        border: var(--dxbl-scheduler-border-width) var(--dxbl-scheduler-border-style) var(--dxbl-scheduler-border-color);
        width: 100%;
        height: 100%;
    }

    .dxbl-sc-time-marker-wrapper-invisible {
        display: none;
    }
}

.dxbl-date-navigator {
    .dxbl-calendar {
        display: flex;
        width: 100%;
    }

    .dxbl-apt-labels-container {
        font-size: 0;
        height: 5px;
        margin-bottom: -5px;

        & > span {
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-top: -2px;
        }

        & > span:nth-last-child(n+2) {
            margin-right: 2px;
        }
    }

    .dxbl-more-labels-container {
        position: relative;
        opacity: 0.7;

        & svg {
            width: 5px;
            height: 5px;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
}

.dxbl-resource-navigator {
    .dxbl-select-all-container {
        padding: 0.25rem 1rem;
    }

    .dxbl-checkbox label {
        padding-left: 0.5rem;
    }

    .dxbl-listbox,
    .dxbl-list-box {
        width: 320px;
        max-height: 300px;
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
    }

    .dxbl-listbox .dxbl-listbox-item,
    .dxbl-list-box > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > ul > li {
        padding-left: 1rem !important; // stylelint-disable-line declaration-no-important
    }

    .dxbl-rn-footer {
        padding: 0.75rem;
    }

    .dxbl-rn-toolbar {
        display: flex;
        justify-content: flex-end;

        & > button:first-child {
            width: 56px;
        }

        & > button:last-child {
            margin-left: 8px;
        }
    }
}

/* Labels & Statuses color */
.dxbl-scheduler-color {
    --dxbl-scheduler-primary-color: #{$dx-primary};
    --dxbl-scheduler-white-color: #{$dx-white};
    --dxbl-scheduler-blue-color: #{$dx-blue};
    --dxbl-scheduler-indigo-color: #{$dx-indigo};
    --dxbl-scheduler-purple-color: #{$dx-purple};
    --dxbl-scheduler-pink-color: #{$dx-pink};
    --dxbl-scheduler-red-color: #{$dx-red};
    --dxbl-scheduler-orange-color: #{$dx-orange};
    --dxbl-scheduler-yellow-color: #{$dx-yellow};
    --dxbl-scheduler-green-color: #{$dx-green};
    --dxbl-scheduler-teal-color: #{$dx-teal};
    --dxbl-scheduler-cyan-color: #{$dx-cyan};
    --dxbl-scheduler-light-color: #{$dx-light};
    --dxbl-scheduler-light-gray-color: #{$dx-gray-200};
    --dxbl-scheduler-dark-color: #{$dx-dark};

    .dxbl-blue-color {
        background-color: var(--dxbl-scheduler-blue-color);
        border-color: var(--dxbl-scheduler-blue-color);
    }

    .dxbl-indigo-color {
        background-color: var(--dxbl-scheduler-indigo-color);
        border-color: var(--dxbl-scheduler-indigo-color);
    }

    .dxbl-purple-color {
        background-color: var(--dxbl-scheduler-purple-color);
        border-color: var(--dxbl-scheduler-purple-color);
    }

    .dxbl-pink-color {
        background-color: var(--dxbl-scheduler-pink-color);
        border-color: var(--dxbl-scheduler-pink-color);
    }

    .dxbl-red-color {
        background-color: var(--dxbl-scheduler-red-color);
        border-color: var(--dxbl-scheduler-red-color);
    }

    .dxbl-orange-color {
        background-color: var(--dxbl-scheduler-orange-color);
        border-color: var(--dxbl-scheduler-orange-color);
    }

    .dxbl-yellow-color {
        background-color: var(--dxbl-scheduler-yellow-color);
        border-color: var(--dxbl-scheduler-yellow-color);
    }

    .dxbl-green-color {
        background-color: var(--dxbl-scheduler-green-color);
        border-color: var(--dxbl-scheduler-green-color);
    }

    .dxbl-teal-color {
        background-color: var(--dxbl-scheduler-teal-color);
        border-color: var(--dxbl-scheduler-teal-color);
    }

    .dxbl-cyan-color {
        background-color: var(--dxbl-scheduler-cyan-color);
        border-color: var(--dxbl-scheduler-cyan-color);
    }

    .dxbl-light-color {
        background-color: var(--dxbl-scheduler-light-color);
    }

    .dxbl-light-gray-color {
        background-color: var(--dxbl-scheduler-light-gray-color);
        border-color: var(--dxbl-scheduler-light-gray-color);
    }

    .dxbl-dark-font-color {
        color: var(--dxbl-scheduler-dark-color);
    }

    .dxbl-white-font-color {
        color: var(--dxbl-scheduler-white-color);
    }
}

.dxbl-itemlist-dropdown > .dxbl-dropdown-dialog.dxbl-toolbar-dropdown-item > .dxbl-dropdown-body {
    padding: 0;

    & .dxbl-date-navigator > dxbl-calendar {
        border: 0;
    }

    & .dxbl-text-edit {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }
}

/* Appointment edit dialog */
.dxbl-apt-edit-dialog {
    &.dxbl-scheduler-color {
        border: 0;

        & .dxbl-sc-label-item {
            margin-right: calc(1.5rem * 0.7);
        }

        & .dxbl-sc-fl-item {
            display: flex;
        }

        & .dxbl-sc-fl-item-with-icon > .dxbl-text {
            padding-left: calc(0.375rem * 2 + 1rem);
        }

        & .dxbl-sc-dialog-title {
            margin-right: auto;
        }

        & div.dxbl-image {
            width: var(--dxbl-image-width);
            height: var(--dxbl-image-height);
        }

        & > .dxbl-apt-edit-dialog-body,
        & > .dxbl-modal-content > .dxbl-modal-body > .dxbl-apt-edit-dialog-body {
            > .dxbl-fl.dxbl-fl-with-paddings {
                --dxbl-fl-padding-x: 0;
                --dxbl-fl-padding-y: 0;
            }
        }
    }

    .dxbl-week-button-container {
        display: flex;
        flex-wrap: wrap;

        & > button {
            margin-left: 0.375rem;
        }
    }

    .dxbl-apt-edit-dialog-header {
        font-size: 1.4em;
    }

    &.dxbl-sc-hint {
        min-width: 276px;
        max-width: 450px;
    }

    &.dxbl-sc-mobile {
        margin: 0;
        height: 100%;
        width: initial;
        max-width: initial;
    }
}

.dxbl-apt-edit-dialog-header {
    width: 100%;
    border-top-left-radius: var(--dxbl-popup-border-radius);
    border-top-right-radius: var(--dxbl-popup-border-radius);
    display: flex;
    align-items: center;
    padding: var(--dxbl-popup-header-padding-y) var(--dxbl-popup-header-padding-x);
}

.dxbl-apt-edit-dialog-body {
    padding: var(--dxbl-popup-body-padding-y) var(--dxbl-popup-body-padding-x);

    & .dxbl-sc-fl-item-content {
        flex-flow: row nowrap;
        display: flex;
        width: 100%;

        & > button {
            width: 75px;
            margin-right: 0.75rem;
            white-space: nowrap;
        }
    }

    & .dxbl-text-edit {
        width: 100%;
    }
}

.dxbl-sc-fl-item-icon {
    padding: 0.25003125rem calc(var(--dxbl-row-item-spacing-x) * 0.5);
    padding-left: 0;
}

.dxbl-apt-edit-compact-dialog-body .dxbl-text-edit {
    width: 100%;
}

.dxbl-modal > .dxbl-modal-root > .dxbl-popup.dxbl-apt-edit-dialog > .dxbl-modal-content > .dxbl-modal-body {
    padding: 0;
}

.dxbl-sc-label-item {
    padding: 4px;
    margin: 0 0.5rem 0.7rem 0.3rem;
    border-radius: 50%;
}

.dxbl-recurrent-apt-delete-dialog,
.dxbl-recurrent-apt-edit-dialog {
    .dxbl-sc-button-margin-start {
        margin-left: 0.5rem;
    }
}

.dxbl-scheduler-color-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;

    & .dxbl-scheduler-color-item-icon {
        margin: 0 0.5rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
    }
}

.dxbl-recurrence-model {
    .dxbl-recurrence-settings {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.dxbl-sc-hint {
    --dxbl-scheduler-flyout-content-padding-x: #{$dx-scheduler-flyout-content-padding-x};
    --dxbl-scheduler-flyout-content-padding-y: #{$dx-scheduler-flyout-content-padding-y};
    --dxbl-scheduler-flyout-background-color: #{$dx-scheduler-background-color};
    --dxbl-scheduler-flyout-border-color: #{$dx-scheduler-border-color};
    --dxbl-scheduler-flyout-border-style: #{$dx-scheduler-border-style};
    --dxbl-scheduler-flyout-border-width: #{$dx-scheduler-border-width};
    --dxbl-scheduler-flyout-border-radius: #{$dx-scheduler-border-radius};

    width: max-content;

    .dxbl-apt-edit-compact-dialog-header,
    .dxbl-sc-tooltip-header {
        font-size: 1.4em;
        padding: 0.6rem 1.1rem;
    }

    &.dxbl-apt-edit-dialog {
        .dxbl-apt-edit-compact-dialog-header {
            border-bottom: var(--dxbl-scheduler-flyout-border-width) var(--dxbl-scheduler-flyout-border-style) var(--dxbl-scheduler-flyout-border-color);
            border-top-left-radius: var(--dxbl-scheduler-flyout-border-radius);
            border-top-right-radius: var(--dxbl-scheduler-flyout-border-radius);
        }

        .dxbl-apt-edit-compact-dialog-body {
            padding: var(--dxbl-scheduler-flyout-content-padding-x) var(--dxbl-scheduler-flyout-content-padding-y);
        }
    }

    &.dxbl-sc-tooltip {
        max-width: 370px;

        &.dxbl-scheduler-color {
            border: 0;

            & .dxbl-sc-label-item {
                margin-top: 0.5rem;
                margin-bottom: 0;
            }
        }

        .dxbl-sc-tooltip-header {
            border-bottom: var(--dxbl-scheduler-flyout-border-width) var(--dxbl-scheduler-flyout-border-style) var(--dxbl-scheduler-flyout-border-color);
            border-top-left-radius: var(--dxbl-scheduler-flyout-border-radius);
            border-top-right-radius: var(--dxbl-scheduler-flyout-border-radius);
        }

        .dxbl-sc-tooltip-container {
            padding: var(--dxbl-scheduler-flyout-content-padding-x) var(--dxbl-scheduler-flyout-content-padding-y);
        }

        .dxbl-sc-tooltip-item {
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 1rem;

            .dxbl-sc-tooltip-item-icon {
                margin-left: 0.25rem;
                padding-right: 0.75rem;
            }
        }
    }

    .dxbl-sc-subject {
        font-size: 1.1em;
        font-weight: 600;
    }
}

.dxbl-sc-table {
    width: 100%;
    vertical-align: top;
    border-color: var(--dxbl-scheduler-border-color);
    border-style: var(--dxbl-scheduler-border-style);
    border-width: 0;
    border-spacing: 0;
    border-collapse: separate;

    & > tbody {
        vertical-align: inherit;
    }

    & > tbody,
    & > tfoot,
    & > thead {
        border-color: inherit;
        border-style: inherit;
        border-width: 0;

        & > tr {
            border-color: inherit;
            border-style: inherit;
            border-width: 0;

            & > td,
            & > th {
                padding: 0.25rem;
                border-color: inherit;
                border-style: inherit;
                border-width: 0;
                border-bottom-width: var(--dxbl-scheduler-border-width);
                border-right-width: var(--dxbl-scheduler-border-width);

                &:last-child {
                    border-right-width: 0;
                }
            }

            &:last-child > td {
                border-bottom-width: 0;
            }
        }
    }
}

/* Media queries */

@media (max-height: 320px) {
    .dxbl-toolbar-dialog .dxbl-resource-navigator .dxbl-listbox,
    .dxbl-toolbar-dialog .dxbl-resource-navigator .dxbl-list-box {
        max-height: 140px !important; // stylelint-disable-line declaration-no-important
    }
}

@media (max-width: 450px) {
    .dxbl-apt-edit-dialog {
        width: auto;
    }
}

@media (max-width: 560px) {
    .dxbl-scheduler {
        .dxbl-sc-table tr > td {
            &.dxbl-sc-minute-cell {
                left: 1.85em;
            }

            &.dxbl-sc-hour-cell {
                width: 1.85em;
            }

            &.dxbl-sc-hour-cell,
            &.dxbl-sc-minute-cell,
            &.dxbl-sc-time-rulers-caption {
                font-size: 0.685rem;
                padding: 0.125rem;
            }
        }

        .dxbl-sc-time-rulers-caption {
            width: 4.48em;
        }

        .dxbl-sc-table.dxbl-hour-time-scale tr > td.dxbl-sc-hour-cell,
        .dxbl-sc-table tr > td.dxbl-sc-time-rulers-caption {
            width: 3em;
        }

        .dxbl-sc-table.dxbl-hour-time-scale tr > td.dxbl-sc-minute-cell {
            left: 3em;
        }

        .dxbl-sc-table:not(.dxbl-hour-time-scale) tr > td {
            &.dxbl-sc-hour-cell {
                width: 1.5em;
            }

            &.dxbl-sc-minute-cell {
                width: 1.5em;
                left: 1.5em;
            }
        }

        .dxbl-sc-hour-min-text-compact {
            display: initial;
        }

        .dxbl-sc-hour-text,
        .dxbl-sc-minute-cell span {
            display: none;
        }

        .dxbl-sc-dayview .dxbl-sc-date-hr {
            padding-left: 0;
            padding-right: 0;
        }

        .dxbl-sc-date-hr-wrapper {
            flex-direction: column-reverse;
            align-items: center;
            font-size: 0.7rem;

            .dxbl-sc-date-hr-month {
                display: none;
            }

            .dxbl-sc-date-hr-month,
            .dxbl-sc-date-hr-day {
                margin-right: 0;
            }

            .dxbl-sc-date-hr-day {
                font-size: 0.9rem;
            }
        }
    }
}

@media (max-width: map-get($dx-window-breakpoints, "md")) {
    .dxbl-apt-edit-dialog {
        --dxbl-image-width: #{$dx-image-size};

        .dxbl-sc-fl-item:not(.dxbl-sc-fl-item-with-icon) {
            padding-left: calc(var(--dxbl-image-width) + var(--dxbl-row-item-spacing-x));
        }
    }
}
