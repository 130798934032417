@use "mixins/common" as cm;
@use "mixins/tabs" as tm;

@mixin tabs-positions {
    $positions: top, right, bottom, left;

    @each $position in $positions {
        $mirror-pos: tm.mirror-position($position);

        &.dxbl-tabs-#{$position} {
            @if $position != top {
                display: flex;

                > .dxbl-tabs-tablist > ul {
                    margin-bottom: 0;
                }
            }

            @if $position == left or $position == right {
                --dxbl-tabs-tab-width: #{$dx-tabs-vertical-tab-width};

                height: 100%;
            }

            @if $position == left {
                flex-direction: row;
            }

            @else if $position == right {
                flex-direction: row-reverse;
            }

            @else if $position == bottom {
                flex-direction: column-reverse;
            }

            > .dxbl-tabs-tablist {
                border: {
                    #{$mirror-pos}: var(--dxbl-tabs-separator-border-width) var(--dxbl-tabs-separator-border-style) var(--dxbl-tabs-separator-border-color);

                    @include tm.border-radius($position, $dx-tabs-tab-border-radius);
                }

                padding-#{$mirror-pos}: 0;

                @if $position == left or $position == right {
                    display: flex;
                    flex-direction: column;

                    > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > ul,
                    > ul {
                        flex-direction: column;

                        > li > .dxbl-tabs-item:not(.dxbl-tabs-tmpl) > .dxbl-text {
                            width: calc(100% + var(--dxbl-tabs-separator-border-width));
                        }
                    }

                    > .dxbl-btn {
                        width: 100%;
                    }
                }

                > .dxbl-scroll-viewer,
                > ul {
                    margin-#{$mirror-pos}: calc(-1 * var(--dxbl-tabs-separator-border-width));
                }

                > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > ul,
                > ul {
                    > li > .dxbl-tabs-item:not(.dxbl-tabs-tmpl) {
                        border: {
                            @include tm.border-radius($position, $dx-tabs-tab-border-radius);
                        }

                        @include cm.active-state() {
                            border-#{$mirror-pos}-color: var(--dxbl-tabs-bg);

                            @include tm.active-tab-selected-element($dx-tabs-tab-selected-element-height, $dx-border-width, $position);

                            @include cm.hover-state() {
                                border-#{$mirror-pos}-color: var(--dxbl-tabs-bg);
                            }
                        }
                    }
                }

                > .dxbl-btn {
                    margin-#{$mirror-pos}: calc(-1 * var(--dxbl-tabs-separator-border-width));
                }
            }
        }
    }
}
