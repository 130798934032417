@mixin background-color-with-opacity($color, $opacity) {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $color;
    opacity: $opacity;
}

@mixin disable-accessibility-border {
    outline: 0;
}

