/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
@import "mixins/html-editor";

.dxbl-html-editor.dxbl-invisible {
    display: none;
}

.dxbl-html-editor {
    --dxbl-html-editor-invalid-color: #{$dx-html-editor-invalid-color};
    --dxbl-html-editor-border-radius: #{$dx-html-editor-border-radius};
    --dxbl-html-editor-primary-color: #{$dx-html-editor-primary-color};
    --dxbl-html-editor-border-width: #{$dx-html-editor-border-width};
    --dxbl-html-editor-border-style: #{$dx-html-editor-border-style};
    --dxbl-html-editor-border-color: #{$dx-html-editor-border-color};
    --dxbl-html-editor-table-resize-frame: #{$dx-html-editor-table-resize-frame};
    --dxbl-html-editor-block-font-color: #{$dx-html-editor-block-font-color};
    --dxbl-html-editor-invalid-message-color: #{$dx-html-editor-white-color};
    --dxbl-html-editor-invalid-message-font-size: #{$dx-html-editor-invalid-message-font-size};
    --dxbl-html-editor-invalid-message-padding-x: #{$dx-html-editor-invalid-message-padding-x};
    --dxbl-html-editor-invalid-message-padding-y: #{$dx-html-editor-invalid-message-padding-y};
    --dxbl-html-editor-resize-border-width: #{$dx-html-editor-border-width};
    --dxbl-html-editor-resize-border-style: #{$dx-html-editor-resize-frame-border-style};
    --dxbl-html-editor-resizable-handle-size: #{$dx-html-editor-resizable-handle-size};
    --dxbl-html-editor-content-padding-x: #{$dx-html-editor-content-padding-x};
    --dxbl-html-editor-content-padding-y: #{$dx-html-editor-content-padding-y};
    --dxbl-html-editor-placeholder-color: #{$dx-html-editor-placeholder-color};
    --dxbl-html-editor-placeholder-offset: #{$dx-html-editor-placeholder-offset};
    --dxbl-html-editor-placeholder-opacity: #{$dx-html-editor-placeholder-opacity};
    --dxbl-html-editor-code-block-background-color: #{$dx-html-editor-code-block-background-color};
    --dxbl-html-editor-code-block-padding: #{$dx-html-editor-code-block-padding};
    --dxbl-html-editor-list-inline-start: #{$dx-html-editor-list-inline-start};
    --dxbl-html-editor-list-inline-end: #{$dx-html-editor-list-inline-end};
    --dxbl-html-editor-list-width: #{$dx-html-editor-list-width};
    --dxbl-html-editor-blockquote-padding-inline-start: #{$dx-html-editor-blockquote-padding-inline-start};
    --dxbl-html-editor-blockquote-border-inline-start-width: #{$dx-html-editor-blockquote-border-inline-start-width};
    --dxbl-html-editor-blockquote-border-inline-start-style: #{$dx-html-editor-blockquote-border-inline-start-style};
    --dxbl-html-editor-blockquote-margin-x: #{$dx-html-editor-blockquote-margin-x};
    --dxbl-html-editor-blockquote-margin-y: #{$dx-html-editor-blockquote-margin-y};
    --dxbl-html-editor-table-padding-x: #{$dx-html-editor-table-padding-x};
    --dxbl-html-editor-table-padding-y: #{$dx-html-editor-table-padding-y};
    --dxbl-html-editor-variable-padding-x: #{$dx-html-editor-variable-padding-x};
    --dxbl-html-editor-variable-padding-y: #{$dx-html-editor-variable-padding-y};
    --dxbl-html-editor-variable-border-radius: #{$dx-html-editor-variable-border-radius};
    --dxbl-html-editor-variable-opacity: #{$dx-html-editor-variable-opacity};

    display: flex;
    flex-direction: column;
    position: relative;
    border: var(--dxbl-html-editor-border-width) var(--dxbl-html-editor-border-style) var(--dxbl-html-editor-border-color);
    border-radius: var(--dxbl-html-editor-border-radius);
    width: 100%;

    .dxbl-office-ribbon {
        overflow: hidden;
        width: 100%;
        border-bottom: var(--dxbl-html-editor-border-width) var(--dxbl-html-editor-border-style) var(--dxbl-html-editor-border-color);
    }

    .dxbl-html-editor-combobox-size {
        width: 5.625rem;
    }

    .dxbl-html-editor-combobox-font {
        width: 9.375rem;
    }

    .dx-htmleditor {
        border: var(--dxbl-html-editor-border-width) var(--dxbl-html-editor-border-style) transparent;
    }

    .dx-htmleditor.dx-state-focused {
        border-color: var(--dxbl-html-editor-primary-color);
    }

    .dx-htmleditor.dx-invalid {
        border-color: var(--dxbl-html-editor-invalid-color);
    }

    .dx-state-invisible {
        display: none !important;
    }

    .dx-invalid-message.dx-overlay-wrapper {
        pointer-events: none;
        visibility: hidden;
    }

    .dx-invalid-message.dx-invalid-message-always,
    .dx-state-focused.dx-invalid .dx-invalid-message-auto {
        visibility: visible;
    }

    .dx-invalid-message > .dx-overlay-content {
        color: var(--dxbl-html-editor-invalid-message-color);
        background-color: var(--dxbl-html-editor-invalid-color);
        border-radius: var(--dxbl-html-editor-border-radius);
        display: inline-block;
        border-width: 0;
        padding: var(--dxbl-html-editor-invalid-message-padding-y) var(--dxbl-html-editor-invalid-message-padding-x);
        font-size: var(--dxbl-html-editor-invalid-message-font-size);
        line-height: normal;
        word-wrap: break-word;
        position: absolute;
        pointer-events: auto;
        z-index: 1000;
        outline: 0;
        overflow: clip;
    }

    .dx-mention {
        color: var(--dxbl-html-editor-primary-color);
        padding: 1px 0;
    }

    .dxbl-widget-container {
        display: flex;
        flex-grow: 1;
        width: 100%;
        height: 10%;

        .dx-quill-container {
            box-sizing: border-box;
            display: flex;
            flex: 1;
            flex-basis: auto;
            min-height: 1px;
            margin: 0;
            position: relative;
            overflow: hidden;

            .dx-resize-frame.dx-resizable {
                border: var(--dxbl-html-editor-resize-border-width) var(--dxbl-html-editor-resize-border-style) var(--dxbl-html-editor-primary-color);
                pointer-events: auto;
                box-sizing: content-box;
                position: absolute;

                .dx-resizable-handle {
                    position: absolute;
                    z-index: 50;
                }

                .dx-resizable-handle-top,
                .dx-resizable-handle-bottom {
                    left: 0;
                    width: 100%;
                    height: var(--dxbl-html-editor-resizable-handle-size);
                }

                .dx-resizable-handle-left,
                .dx-resizable-handle-right {
                    top: 0;
                    height: 100%;
                    width: var(--dxbl-html-editor-resizable-handle-size);
                }

                .dx-resizable-handle-top {
                    top: 0;
                    cursor: s-resize;
                }

                .dx-resizable-handle-bottom {
                    bottom: 0;
                    cursor: s-resize;
                }

                .dx-resizable-handle-left {
                    left: 0;
                    cursor: e-resize;
                }

                .dx-resizable-handle-right {
                    right: 0;
                    cursor: e-resize;
                }

                .dx-resizable-handle-bottom::after {
                    bottom: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    left: calc(50% - var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-top::after {
                    top: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    left: calc(50% - var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-left::after {
                    left: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    top: calc(50% - var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-right::after {
                    right: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    top: calc(50% - var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-corner-bottom-right,
                .dx-resizable-handle-corner-bottom-left,
                .dx-resizable-handle-corner-top-right,
                .dx-resizable-handle-corner-top-left,
                .dx-resizable-handle-right::after,
                .dx-resizable-handle-left::after,
                .dx-resizable-handle-top::after,
                .dx-resizable-handle-bottom::after {
                    background-color: var(--dxbl-html-editor-primary-color);
                    content: "";
                    position: absolute;
                    width: calc(2 * var(--dxbl-html-editor-resizable-handle-size));
                    height: calc(2 * var(--dxbl-html-editor-resizable-handle-size));
                    border: var(--dxbl-html-editor-border-width) var(--dxbl-html-editor-border-style) var(--dxbl-html-editor-border-color);
                }

                .dx-resizable-handle-corner-bottom-right {
                    cursor: se-resize;
                    bottom: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    right: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-corner-bottom-left {
                    cursor: ne-resize;
                    bottom: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    left: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-corner-top-right {
                    cursor: ne-resize;
                    top: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    right: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                }

                .dx-resizable-handle-corner-top-left {
                    cursor: se-resize;
                    top: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                    left: calc(-1 * var(--dxbl-html-editor-resizable-handle-size));
                }
            }

            .dx-table-resize-frame {
                position: absolute;
                pointer-events: none;
            }

            .dx-table-resize-frame .dx-htmleditor-row-resizer {
                height: var(--dxbl-html-editor-table-resize-frame);
                left: 0;
                width: 100%;
                cursor: row-resize;
            }

            .dx-table-resize-frame > div {
                position: absolute;
                pointer-events: auto;
                background-color: transparent;
            }

            .dx-table-resize-frame .dx-htmleditor-column-resizer {
                width: var(--dxbl-html-editor-table-resize-frame);
                top: 0;
                height: 100%;
                cursor: col-resize;
            }
        }

        .dx-htmleditor-content {
            box-sizing: border-box;
            line-height: 1.42;
            width: 100%;
            outline: 0;
            overflow-y: auto;
            padding: var(--dxbl-html-editor-content-padding-y) var(--dxbl-html-editor-content-padding-x);
            white-space: pre-wrap;
            word-wrap: break-word;

            > * {
                cursor: text;
            }

            li {
                list-style-type: none;
                padding-inline-start: var(--dxbl-html-editor-list-inline-start);
                position: relative;

                &[data-list="bullet"]::before {
                    content: "\2022";
                }

                &::before {
                    display: inline-block;
                    margin-inline-start: calc(0 - var(--dxbl-html-editor-list-inline-start));
                    margin-inline-end: var(--dxbl-html-editor-list-inline-end);
                    text-align: end;
                    white-space: nowrap;
                    width: var(--dxbl-html-editor-list-width);
                }
            }

            li[data-list="ordered"] {
                counter-reset: add-counter-reset($dx-html-editor-max-indent);
                counter-increment: list-0;

                &::before {
                    content: counter(list-0, nth($dx-html-editor-list-style, 1)) ". ";
                }
            }

            blockquote {
                padding-inline-start: var(--dxbl-html-editor-blockquote-padding-inline-start);
                border-inline-start: var(--dxbl-html-editor-blockquote-border-inline-start-width) var(--dxbl-html-editor-blockquote-border-inline-start-style);
                color: var(--dxbl-html-editor-block-font-color);
                border-inline-start-color: var(--dxbl-html-editor-primary-color);
                margin: var(--dxbl-html-editor-blockquote-margin-y) var(--dxbl-html-editor-blockquote-margin-x);
            }

            .ql-code-block-container {
                font-family: monospace;
                background-color: var(--dxbl-html-editor-code-block-background-color);
                color: var(--dxbl-html-editor-block-font-color);
            }

            a {
                pointer-events: none;
            }

            .ql-code-block {
                padding: var(--dxbl-html-editor-code-block-padding);
            }

            &.ql-blank {
                &::before {
                    opacity: var(--dxbl-html-editor-placeholder-opacity);
                    color: var(--dxbl-html-editor-placeholder-color);
                    content: attr(data-placeholder);
                    left: var(--dxbl-html-editor-placeholder-offset);
                    pointer-events: none;
                    position: absolute;
                    right: var(--dxbl-html-editor-placeholder-offset);
                }

                &::after {
                    content: attr(data-placeholder);
                    z-index: -1;
                    visibility: hidden;
                }

                &[data-placeholder] > p {
                    height: 0.1px;
                }
            }

            @include add-counter($dx-html-editor-max-indent);
            @include add-indent-styles($dx-html-editor-max-indent);

            p {
                margin: 0;
                padding: 0;
            }

            table {
                width: 50%;
                border-collapse: collapse;
                table-layout: fixed;
                display: table;
                box-sizing: border-box;
                text-indent: initial;
                unicode-bidi: isolate;
                border-spacing: 2px;
            }

            thead {
                display: table-header-group;
                vertical-align: middle;
                unicode-bidi: isolate;
                border-color: inherit;
            }

            tbody {
                display: table-row-group;
                vertical-align: middle;
                unicode-bidi: isolate;
                border-color: inherit;
            }

            tr {
                display: table-row;
                vertical-align: inherit;
                unicode-bidi: isolate;
                border-color: inherit;
            }

            td,
            th {
                outline: 0;
                border: var(--dxbl-html-editor-border-width) var(--dxbl-html-editor-border-style) var(--dxbl-html-editor-border-color);
                padding: var(--dxbl-html-editor-table-padding-y) var(--dxbl-html-editor-table-padding-x);
            }

            .dx-variable > span {
                position: relative;
                padding: var(--dxbl-html-editor-variable-padding-y) var(--dxbl-html-editor-variable-padding-x);
            }

            .dx-variable > span::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: var(--dxbl-html-editor-variable-border-radius);
                background-color: var(--dxbl-html-editor-primary-color);
                opacity: var(--dxbl-html-editor-variable-opacity);
            }
        }
    }
}

// styles for mentions
.dx-popup-wrapper.dx-suggestion-list-wrapper {
    --dxbl-html-editor-mentions-list-border-width: #{$dx-html-editor-border-width};
    --dxbl-html-editor-mentions-list-border-style: #{$dx-html-editor-border-style};
    --dxbl-html-editor-mentions-list-border-color: #{$dx-html-editor-border-color};
    --dxbl-html-editor-mentions-list-background: #{$dx-bg};
    --dxbl-html-editor-mentions-list-item-border-width: #{$dx-html-editor-mentions-list-item-border-width};
    --dxbl-html-editor-mentions-list-item-border-style: #{$dx-html-editor-mentions-list-item-border-style};
    --dxbl-html-editor-primary-color: #{$dx-html-editor-primary-color};
    --dxbl-html-editor-mentions-scrollbar-width: #{$dx-html-editor-mentions-scrollbar-width};
    --dxbl-html-editor-mentions-scrollbar-color: #{$dx-html-editor-mentions-scrollbar-color};
    --dxbl-html-editor-mentions-list-background-color: #{$dx-html-editor-mentions-list-background-color};
    --dxbl-html-editor-mentions-list-padding-x: #{$dx-html-editor-mentions-list-item-padding-x};
    --dxbl-html-editor-mentions-list-padding-y: #{$dx-html-editor-mentions-list-item-padding-y};

    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1000;
    font-weight: 400;
    line-height: 1.35715;

    & > .dx-overlay-content {
        border: var(--dxbl-html-editor-mentions-list-border-width) var(--dxbl-html-editor-mentions-list-border-style) var(--dxbl-html-editor-mentions-list-border-color);
        background: var(--dxbl-html-editor-mentions-list-background);
        box-shadow: 0 4px 15px -3px rgba(0 0 0 / 10%);
        border-radius: var(--dxbl-html-editor-border-radius);
        position: absolute;
        pointer-events: auto;
        outline: 0;
        overflow: clip;
    }

    .dx-popup-content {
        padding: 1px;
    }

    .dx-list .dx-empty-message,
    .dx-list-item-content {
        padding: 10px;
    }

    .dx-list-item {
        position: static;
        cursor: pointer;
        display: table;
        width: 100%;
        table-layout: fixed;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: var(--dxbl-html-editor-mentions-list-item-border-width) var(--dxbl-html-editor-mentions-list-item-border-style) transparent;
    }

    .dx-suggestion-list .dx-list-item {
        display: block;
    }

    .dx-suggestion-list {
        min-width: 50px;
    }

    .dx-scrollable,
    .dx-scrollable-wrapper,
    .dx-scrollable-container {
        min-height: inherit;
        max-height: inherit;
    }

    .dx-scrollable-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        transform: none;
    }

    .dx-scrollable-content {
        position: relative;
        min-height: 100%;
    }

    .dx-scrollbar-vertical {
        top: 0;
        right: 0;
        height: 100%;
        width: var(--dxbl-html-editor-mentions-scrollbar-width);
        transition: width 0.2s linear 0.15s, background-color 0.2s linear 0.15s;
        background-color: transparent;
    }

    .dx-scrollable-scrollbar {
        position: absolute;
        pointer-events: auto;
    }

    .dx-scrollbar-vertical .dx-scrollable-scroll {
        float: right;
        width: var(--dxbl-html-editor-mentions-scrollbar-width);
        padding-inline-end: 2px;
    }

    .dx-scrollable-scroll-content {
        width: 100%;
        height: 100%;
        background-color: var(--dxbl-html-editor-mentions-scrollbar-color);
    }

    .dx-list .dx-list-item.dx-state-focused {
        border: var(--dxbl-html-editor-mentions-list-item-border-width) var(--dxbl-html-editor-mentions-list-item-border-style) var(--dxbl-html-editor-primary-color);
    }

    .dx-list .dx-list-item.dx-state-hover {
        background-color: var(--dxbl-html-editor-mentions-list-background-color);
    }

    .dx-list-item-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: table-cell;
        width: 100%;
        padding: var(--dxbl-html-editor-mentions-list-padding-y) var(--dxbl-html-editor-mentions-list-padding-x);
    }

    .dx-state-invisible {
        display: none !important;
    }

    .dx-scrollable-native {
        &.dx-scrollable-wrapper > .dx-scrollable-container,
        > div.dx-scrollable-wrapper > .dx-scrollable-container {
            position: relative;
            height: 100%;
        }

        &.dx-scrollable-vertical,
        &.dx-scrollable-vertical > .dx-scrollable-wrapper > .dx-scrollable-container {
            touch-action: pan-y;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &.dx-scrollable-native-ios {
            .dx-scrollable-content {
                min-height: 101%;
                box-sizing: content-box;
            }

            &.dx-scrollable-horizontal {
                .dx-scrollable-content {
                    min-height: 0;
                    padding: 0;
                }
            }
        }

        &.dx-scrollable-native-android .dx-scrollable-content {
            transform: none;
            z-index: 0;
        }
    }
}

.dxbl-html-editor-modal-dialog {
    --dxbl-html-editor-dialog-footer-margin-bottom: #{$dx-html-editor-dialog-footer-margin-bottom};
    --dxbl-html-editor-drop-zone-border-radius: #{$dx-html-editor-drop-zone-border-radius};
    --dxbl-html-editor-drop-zone-border-width: #{$dx-html-editor-drop-zone-border-width};
    --dxbl-html-editor-drop-zone-border-style: #{$dx-html-editor-drop-zone-border-style};
    --dxbl-html-editor-drop-zone-border-color: #{$dx-html-editor-drop-zone-border-color};
    --dxbl-html-editor-drop-zone-background-color: #{$dx-html-editor-drop-zone-background-color};
    --dxbl-html-editor-drop-zone-drag-over-border-color: #{$dx-html-editor-drag-zone-drag-over-border-color};

    .dxbl-dialog-label {
        margin-bottom: $dx-html-editor-dialog-label-margin-bottom;
    }

    .dxbl-dialog-column-direction {
        display: flex;
        flex-direction: column;
    }

    .dxbl-dialog-row-direction {
        display: flex;
        flex-direction: row;
    }

    .dxbl-mobile-dialog-buttons {
        flex-grow: 1;
    }

    .dxbl-mobile-dialog-button {
        flex: 1 1 0px;
    }

    .dxbl-dialog-row-gap {
        row-gap: $dx-html-editor-dialog-row-gap;
    }

    .dxbl-dialog-column-gap {
        column-gap: $dx-html-editor-dialog-column-gap;
    }

    .dxbl-aspect-ratio {
        width: $dx-html-editor-dialog-aspect-ratio-button-size;
        height: $dx-html-editor-dialog-aspect-ratio-button-size;
        padding: 0;
        margin: 0 $dx-html-editor-dialog-column-gap;
    }

    .dxbl-grid-container {
        display: grid;
        grid-template-columns: auto calc($dx-html-editor-dialog-aspect-ratio-button-size + $dx-html-editor-dialog-column-gap * 2) auto;
    }

    .dxbl-grid-column-3 {
        grid-column: 3;
    }

    .dxbl-drop-zone {
        display: flex;
        border: var(--dxbl-html-editor-drop-zone-border-width) var(--dxbl-html-editor-drop-zone-border-style) var(--dxbl-html-editor-drop-zone-border-color);
        align-items: center;
        height: 14.375rem;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.5rem;
        width: 100%;
        border-radius: var(--dxbl-html-editor-drop-zone-border-radius);
        background-color: var(--dxbl-html-editor-drop-zone-background-color);
    }

    .dxbl-drop-zone-drag-over {
        border-color: var(--dxbl-html-editor-drop-zone-drag-over-border-color);
    }

    .dxbl-drop-image-icon {
        width: 48px;
        height: 48px;
    }

    .dxbl-drop-zone-container {
        padding: 0.25rem 0 0;
    }

    .dxbl-stretched {
        flex: 1;
    }
}

.dxbl-modal > .dxbl-modal-root > .dxbl-html-editor-modal-dialog > .dxbl-modal-content > .dxbl-footer {
    background-color: $dx-popup-bg;
    border-top: $dx-html-editor-dialog-footer-border-top;
    margin-bottom: var(--dxbl-html-editor-dialog-footer-margin-bottom);
    padding-top: 0;
}
