// Buttons
.dxbl-btn-secondary:where(:not(.dxbl-btn-standalone)) {
    --dxbl-btn-bg: white;
    --dxbl-btn-color: #{$dx-gray-900};
    --dxbl-btn-border-color: white;
    --dxbl-btn-active-bg: #{$dx-gray-300};
    --dxbl-btn-active-color: #{$dx-gray-900};
    --dxbl-btn-active-border-color: #{$dx-gray-300};
    --dxbl-btn-hover-bg: #{$dx-gray-100};
    --dxbl-btn-hover-color: #{$dx-gray-900};
    --dxbl-btn-hover-border-color: #{$dx-gray-100};
    --dxbl-btn-disabled-bg: white;
    --dxbl-btn-disabled-color: #{$dx-gray-600};
    --dxbl-btn-disabled-border-color: white;

    --dxbl-btn-box-shadow: #{$dx-btn-special-box-shadow};
}

.dxbl-btn-outline-secondary:where(:not(.dxbl-btn-standalone)) {
    --dxbl-btn-border-color: #{$dx-gray-300};
    --dxbl-btn-hover-bg: #{$dx-gray-100};
    --dxbl-btn-hover-color: #{$dx-gray-900};
    --dxbl-btn-hover-border-color: #{$dx-gray-300};
    --dxbl-btn-active-bg: #{$dx-gray-300};
    --dxbl-btn-active-color: #{$dx-gray-900};
    --dxbl-btn-active-border-color: #{$dx-gray-300};
    --dxbl-btn-disabled-border-color: #{$dx-gray-300};
}

.dxbl-btn-text-secondary:where(:not(.dxbl-btn-standalone)) {
    --dxbl-btn-hover-bg: #{$dx-gray-100};
    --dxbl-btn-hover-color: #{$dx-gray-900};
    --dxbl-btn-hover-border-color: #{$dx-gray-300};
    --dxbl-btn-active-bg: #{$dx-gray-300};
    --dxbl-btn-active-color: #{$dx-gray-900};
    --dxbl-btn-active-border-color: #{$dx-gray-300};
}

.dxbl-btn-light:where(:not(.dxbl-btn-standalone)) {
    --dxbl-btn-box-shadow: #{$dx-btn-special-box-shadow};
}
