@import "mixins/common";
@import "mixins/group-control";
@import "mixins/navigation";

.dxbl-accordion {
    --dxbl-accordion-group-font-size: #{$dx-accordion-group-font-size};
    --dxbl-accordion-group-line-height: #{$dx-accordion-group-line-height};
    --dxbl-accordion-group-border-radius: #{$dx-accordion-group-border-radius};
    --dxbl-accordion-group-border-width: #{$dx-accordion-group-border-width};
    --dxbl-accordion-group-border-color: #{$dx-accordion-group-border-color};
    --dxbl-accordion-group-header-font-size: #{$dx-accordion-group-header-font-size};
    --dxbl-accordion-group-header-line-height: #{$dx-accordion-group-header-line-height};
    --dxbl-accordion-group-header-padding-x: #{$dx-accordion-group-header-padding-x};
    --dxbl-accordion-group-header-padding-y: #{$dx-accordion-group-header-padding-y};
    --dxbl-accordion-group-header-text-padding-y: #{$dx-accordion-group-header-text-padding-y};
    --dxbl-accordion-group-header-border-radius: #{$dx-accordion-group-header-border-radius};
    --dxbl-accordion-group-header-bg: #{$dx-accordion-group-header-bg};
    --dxbl-accordion-group-header-color: #{$dx-accordion-group-header-color};
    --dxbl-accordion-group-header-hover-bg: #{$dx-accordion-group-header-hover-bg};
    --dxbl-accordion-group-header-hover-color: #{$dx-accordion-group-header-hover-color};
    --dxbl-accordion-group-header-selected-bg: #{$dx-accordion-group-header-selected-bg};
    --dxbl-accordion-group-header-selected-color: #{$dx-accordion-group-header-selected-color};
    --dxbl-accordion-group-item-font-size: #{$dx-accordion-group-item-font-size};
    --dxbl-accordion-group-item-line-height: #{$dx-accordion-group-item-line-height};
    --dxbl-accordion-group-item-spacing: #{$dx-accordion-group-item-spacing};
    --dxbl-accordion-group-item-content-padding-x: #{$dx-accordion-item-content-padding-x};
    --dxbl-accordion-group-item-text-padding-y: #{$dx-navigation-item-text-padding-y};
    --dxbl-accordion-group-item-content-indent: #{$dx-accordion-item-content-indent};
    --dxbl-accordion-group-item-btn-color: #{$dx-accordion-item-btn-color};
    --dxbl-accordion-group-item-btn-bg: #{$dx-accordion-item-btn-bg};
    --dxbl-accordion-group-item-btn-hover-color: #{$dx-accordion-item-btn-hover-color};
    --dxbl-accordion-group-item-btn-hover-bg: #{$dx-accordion-item-btn-hover-bg};
    --dxbl-accordion-group-item-hover-bg: #{$dx-accordion-item-hover-bg};
    --dxbl-accordion-group-item-hover-color: #{$dx-accordion-item-hover-color};
    --dxbl-accordion-group-item-selection-bg: #{$dx-accordion-item-selection-bg};
    --dxbl-accordion-group-item-selection-color: #{$dx-accordion-item-selection-color};
    --dxbl-accordion-filter-content-bg-color: #{$dx-navigation-filter-content-bg-color};
    --dxbl-accordion-filter-content-color: #{$dx-navigation-filter-content-color};
    --dxbl-accordion-focus-outline-color: #{$dx-treeview-item-focus-outline-color};
    --dxbl-accordion-focus-outline-width: #{$dx-treeview-item-focus-outline-width};
    --dxbl-accordion-focus-outline-offset: #{$dx-treeview-item-focus-outline-offset};

    &.dxbl-sm {
        --dxbl-accordion-group-font-size: #{$dx-accordion-group-font-size-sm};
        --dxbl-accordion-group-line-height: #{$dx-accordion-group-line-height-sm};
        --dxbl-accordion-group-header-font-size: #{$dx-accordion-group-header-font-size-sm};
        --dxbl-accordion-group-header-line-height: #{$dx-accordion-group-header-line-height-sm};
        --dxbl-accordion-group-header-padding-x: #{$dx-accordion-group-header-padding-x-sm};
        --dxbl-accordion-group-header-padding-y: #{$dx-accordion-group-header-padding-y-sm};
        --dxbl-accordion-group-header-text-padding-y: #{$dx-accordion-group-header-text-padding-y-sm};
        --dxbl-accordion-group-item-spacing: #{$dx-accordion-group-item-spacing-sm};
        --dxbl-accordion-group-item-content-indent: #{$dx-accordion-item-content-indent-sm};
        --dxbl-accordion-group-item-content-padding-x: #{$dx-accordion-item-content-padding-x-sm};
        --dxbl-accordion-group-item-text-padding-y: #{$dx-navigation-item-text-padding-y-sm};
        --dxbl-accordion-group-item-font-size: #{$dx-accordion-group-item-font-size-sm};
        --dxbl-accordion-group-item-line-height: #{$dx-accordion-group-item-line-height-sm};
    }

    &.dxbl-lg {
        --dxbl-accordion-group-font-size: #{$dx-accordion-group-font-size-lg};
        --dxbl-accordion-group-line-height: #{$dx-accordion-group-line-height-lg};
        --dxbl-accordion-group-header-font-size: #{$dx-accordion-group-header-font-size-lg};
        --dxbl-accordion-group-header-line-height: #{$dx-accordion-group-header-line-height-lg};
        --dxbl-accordion-group-header-padding-x: #{$dx-accordion-group-header-padding-x-lg};
        --dxbl-accordion-group-header-padding-y: #{$dx-accordion-group-header-padding-y-lg};
        --dxbl-accordion-group-header-text-padding-y: #{$dx-accordion-group-header-text-padding-y-lg};
        --dxbl-accordion-group-item-spacing: #{$dx-accordion-group-item-spacing-lg};
        --dxbl-accordion-group-item-content-indent: #{$dx-accordion-item-content-indent-lg};
        --dxbl-accordion-group-item-content-padding-x: #{$dx-accordion-item-content-padding-x-lg};
        --dxbl-accordion-group-item-text-padding-y: #{$dx-navigation-item-text-padding-y-lg};
        --dxbl-accordion-group-item-font-size: #{$dx-accordion-group-item-font-size-lg};
        --dxbl-accordion-group-item-line-height: #{$dx-accordion-group-item-line-height-lg};
    }

    & .dxbl-accordion-filter {
        & > .dxbl-accordion-group-header {
            padding: var(--dxbl-accordion-group-header-padding-y) var(--dxbl-accordion-group-header-padding-x);

            & .dxbl-navigation-filter {
                border: none;
                background: none;
                width: 100%;

                &.dxbl-text-edit {
                    --dxbl-text-edit-font-size: var(--dxbl-accordion-group-header-font-size);
                    --dxbl-text-edit-line-height: var(--dxbl-accordion-group-header-line-height);
                    --dxbl-text-edit-padding-y: var(--dxbl-accordion-group-header-text-padding-y);

                    box-shadow: none;

                    & > input {
                        margin-left: var(--dxbl-accordion-group-item-spacing);
                    }

                    & > .dxbl-edit-btn-clear {
                        padding: 0;
                    }
                }
            }
        }
    }

    @include navigation-filter-mark(var(--dxbl-accordion-filter-content-bg-color), var(--dxbl-accordion-filter-content-color));

    & .dxbl-accordion-container:not([data-dx-focus-hidden]) {
        .dxbl-accordion-group-header,
        .dxbl-accordion-group-body,
        .dxbl-accordion-group-body .dxbl-accordion-item-content,
        .dxbl-accordion-tmpl {
            &:focus-visible {
                @include navigation-item-focused-state("--dxbl-accordion-focus-outline-width", "--dxbl-accordion-focus-outline-color", "--dxbl-accordion-focus-outline-offset");
            }
        }
    }

    & .dxbl-accordion-container[data-dx-focus-hidden] {
        .dxbl-accordion-group-header,
        .dxbl-accordion-group-body,
        .dxbl-accordion-group-body .dxbl-accordion-item-content,
        .dxbl-accordion-tmpl {
            &:focus-visible {
                box-shadow: none;
                position: relative;
                outline: unset;
            }
        }
    }

    & .dxbl-accordion-group {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;

        &.dxbl-group {
            --dxbl-group-border-radius: var(--dxbl-accordion-group-border-radius);
            --dxbl-group-border-width: var(--dxbl-accordion-group-border-width);
            --dxbl-group-border-color: var(--dxbl-accordion-group-border-color);
            --dxbl-group-font-size: var(--dxbl-accordion-group-font-size);
            --dxbl-group-line-height: var(--dxbl-accordion-group-line-height);
            --dxbl-group-header-border-radius: var(--dxbl-accordion-group-header-border-radius);
            --dxbl-group-header-font-size: var(--dxbl-accordion-group-header-font-size);
            --dxbl-group-header-line-height: var(--dxbl-accordion-group-header-line-height);
            --dxbl-group-header-padding-x: var(--dxbl-accordion-group-header-padding-x);
            --dxbl-group-header-padding-y: var(--dxbl-accordion-group-header-padding-y);
            --dxbl-group-header-spacing: var(--dxbl-accordion-group-item-spacing);
        }

        &:not(:first-child) {
            border-top: 0;

            &:not(:last-child) {
                &,
                & > .dxbl-accordion-group-header {
                    border-radius: 0;
                }
            }

            &:last-child {
                &,
                & > .dxbl-accordion-group-header {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }

        &:first-child:not(:last-child) {
            &,
            & > .dxbl-accordion-group-header {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        & .dxbl-accordion-group-header {
            & > .dxbl-accordion-item-content:not(:last-child) {
                margin-right: var(--dxbl-accordion-group-item-spacing);
            }

            & > .dxbl-btn.dxbl-group-expand-btn {
                margin: 0;
            }

            & > .dxbl-accordion-item-content {
                display: flex;
                text-decoration: none;
                position: relative;
                flex-grow: 1;
                color: inherit;
                align-items: center;

                & > .dxbl-accordion-item-text-container.dxbl-text {
                    --dxbl-text-padding-y: var(--dxbl-accordion-group-header-text-padding-y);
                    --dxbl-text-padding-x: 0;

                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }

        @include not-disabled-state() {
            &:not(.dxbl-accordion-filter) {
                @include not-active-state() {
                    & > .dxbl-accordion-group-header {
                        cursor: pointer;
                    }
                }

                & > .dxbl-accordion-group-header:hover {
                    @include panel-header-hover-style("--dxbl-accordion-group-header-hover-bg", "--dxbl-accordion-group-header-hover-color");
                }

                @include active-state() {
                    & > .dxbl-accordion-group-header {
                        @include panel-header-selected-style("--dxbl-accordion-group-header-selected-bg", "--dxbl-accordion-group-header-selected-color");
                    }
                }
            }
        }

        &:not(.dxbl-accordion-filter) {
            &.dxbl-active > .dxbl-accordion-group-header,
            & > .dxbl-accordion-group-header:hover,
            & > .dxbl-accordion-group-header:active {
                & a.dxbl-accordion-item-content {
                    color: inherit;
                }
            }

            & > .dxbl-accordion-group-header {
                align-items: center;
            }
        }

        @include disabled-state() {
            cursor: default;

            .dxbl-accordion-group-header {
                & .dxbl-accordion-item-text-container:not(.dxbl-accordion-item-text-tmpl) {
                    opacity: $dx-accordion-group-header-disabled-opacity;
                }
            }
        }

        & .dxbl-accordion-item:not(.dxbl-accordion-group) {
            font-size: var(--dxbl-accordion-group-item-font-size);
            line-height: var(--dxbl-accordion-group-item-line-height);

            & > .dxbl-accordion-item-content {
                display: flex;
                align-items: center;
                text-decoration: none;
                position: relative;
                color: inherit;
            }

            @include active-state() {
                & > .dxbl-accordion-item-content {
                    @include navigation-item-selected-state("--dxbl-accordion-group-item-selection-bg", "--dxbl-accordion-group-item-selection-color", true);
                }
            }

            @include not-disabled-state() {
                &.dxbl-active,
                &:hover,
                &:active {
                    color: inherit;
                }

                @include not-active-state() {
                    cursor: pointer;
                    color: inherit;

                    & > .dxbl-accordion-item-content:hover {
                        @include navigation-item-hover-state("--dxbl-accordion-group-item-hover-bg", "--dxbl-accordion-group-item-hover-color");
                    }
                }
            }

            @include disabled-state() {
                & > .dxbl-accordion-item-content {
                    & .dxbl-accordion-item-text-container:not(.dxbl-accordion-item-text-tmpl) {
                        opacity: 0.5;
                    }
                }
            }
        }

        & .dxbl-accordion-item-content {
            & .dxbl-accordion-group-expand-btn-container + * {
                margin-left: var(--dxbl-accordion-group-item-spacing);
            }

            & > .dxbl-image {
                flex: 0 0 auto;

                &:not(:last-child) {
                    margin-right: var(--dxbl-accordion-group-item-spacing);
                }
            }

            & .dxbl-accordion-item-text-container {
                display: flex;
                flex: 1 1 auto;
                align-items: center;

                &.dxbl-text {
                    --dxbl-text-padding-x: 0;
                }

                &:not(:last-child) {
                    margin-right: var(--dxbl-accordion-group-item-spacing);
                }

                &.dxbl-accordion-item-text-tmpl {
                    @include group-text-container-style();
                }
            }

            & .dxbl-badge {
                margin-left: var(--dxbl-accordion-group-item-spacing);
            }
        }

        & > .dxbl-accordion-group-body {
            & .dxbl-accordion-item {
                & .dxbl-accordion-item-indent,
                & .dxbl-accordion-item-indent-end {
                    min-width: calc((var(--dxbl-accordion-item-indent, 0) - 1) * var(--dxbl-accordion-group-item-content-indent));
                }
            }

            & .dxbl-accordion-item-content {
                padding: 0 var(--dxbl-accordion-group-item-content-padding-x);

                & .dxbl-accordion-group-expand-btn-container {
                    @include navigation-expand-button-style("--dxbl-accordion-group-item-btn-color", null, "--dxbl-accordion-group-item-btn-hover-color",
                        "--dxbl-accordion-group-item-btn-hover-bg", "--dxbl-accordion-group-item-btn-hover-color", true);
                }
            }
        }
    }
}
