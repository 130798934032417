@import "common";

@mixin state-selected-range-style($bg-color-var, $color-var, $border-width-var: null, $root-bg-color-var: null) {
    @include element-calculated-style($bg-color-var, $color-var, $dx-calendar-item-selected-range-bg,
        $dx-state-base-bg, $dx-calendar-item-selected-range-bg-alpha, $border-width-var, $root-bg-color-var);
}

@mixin calendar-item-focus-state() {
    &:focus-within {
        outline: var(--dxbl-calendar-item-focus-outline-width) solid var(--dxbl-calendar-item-focus-outline-color);
        outline-offset: calc(var(--dxbl-calendar-item-focus-outline-width) * -1);
    }
}

@mixin calendar-button-focus-state() {
    --dxbl-btn-focus-box-shadow: none;
    --dxbl-btn-focus-outline-color: var(--dxbl-calendar-item-focus-outline-color);
    --dxbl-btn-focus-outline-size: var(--dxbl-calendar-item-focus-outline-width);
    --dxbl-btn-focus-outline-offset: var(--dxbl-calendar-footer-btn-outline-offset);
}

@mixin calendar-multi-view-header-titles-style() {
    > div.dxbl-btn.dxbl-calendar-header-title-btn {
        flex: 1 0 0;
        display: inline-flex;
        padding: 0;

        & > .dxbl-btn.dxbl-calendar-header-title-btn:first-of-type {
            --dxbl-calendar-multi-view-header-title-button-padding-right: #{$dx-calendar-multi-view-header-title-button-padding};

            padding-right: var(--dxbl-calendar-multi-view-header-title-button-padding-right);

            &.dxbl-sm {
                --dxbl-calendar-multi-view-header-title-button-padding-right: #{$dx-calendar-multi-view-header-title-button-padding-sm};
            }

            &.dxbl-lg {
                --dxbl-calendar-multi-view-header-title-button-padding-right: #{$dx-calendar-multi-view-header-title-button-padding-lg};
            }
        }

        & > .dxbl-btn.dxbl-calendar-header-title-btn:last-of-type {
            --dxbl-calendar-multi-view-header-title-button-padding-left: #{$dx-calendar-multi-view-header-title-button-padding};

            padding-left: var(--dxbl-calendar-multi-view-header-title-button-padding-left);

            &.dxbl-sm {
                --dxbl-calendar-multi-view-header-title-button-padding-left: #{$dx-calendar-multi-view-header-title-button-padding-sm};
            }

            &.dxbl-lg {
                --dxbl-calendar-multi-view-header-title-button-padding-left: #{$dx-calendar-multi-view-header-title-button-padding-lg};
            }
        }
    }

    > .dxbl-calendar-header-previous-period-btn {
        & + div.dxbl-btn.dxbl-calendar-header-title-btn {
            & > .dxbl-btn.dxbl-calendar-header-title-btn:first-of-type {
                --dxbl-calendar-multi-view-header-title-button-padding-right: #{$dx-calendar-multi-view-month-view-header-title-button-padding};

                &.dxbl-sm {
                    --dxbl-calendar-multi-view-header-title-button-padding-right: #{$dx-calendar-multi-view-month-view-header-title-button-padding-sm};
                }

                &.dxbl-lg {
                    --dxbl-calendar-multi-view-header-title-button-padding-right: #{$dx-calendar-multi-view-month-view-header-title-button-padding-lg};
                }
            }

            & > .dxbl-btn.dxbl-calendar-header-title-btn:last-of-type {
                --dxbl-calendar-multi-view-header-title-button-padding-left: #{$dx-calendar-multi-view-month-view-header-title-button-padding};

                &.dxbl-sm {
                    --dxbl-calendar-multi-view-header-title-button-padding-left: #{$dx-calendar-multi-view-month-view-header-title-button-padding-sm};
                }

                &.dxbl-lg {
                    --dxbl-calendar-multi-view-header-title-button-padding-left: #{$dx-calendar-multi-view-month-view-header-title-button-padding-lg};
                }
            }
        }
    }
}
