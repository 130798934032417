.dxbl-toast-portal {
    overflow: hidden;
    animation-fill-mode: both;

    --dxbl-toast-animation-duration: 0.75s;
    --dxbl-toast-animation-type: unset;

    @keyframes dxbl-toast-animation-slide-in-left {
        from {
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }

        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-slide-in-right {
        from {
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-slide-in-up {
        from {
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-slide-in-down {
        from {
            transform: translate3d(0, -100%, 0);
            visibility: visible;
        }

        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes dxbl-toast-animation-fade-in-left {
        from {
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-fade-in-right {
        from {
            opacity: 0;
            transform: translate3d(100%, 0, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-fade-in-up {
        from {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes dxbl-toast-animation-fade-in-down {
        from {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @mixin toast-direction-animation($animation-name) {
        &.dxbl-toast-align-left > .dxbl-toast {
            --dxbl-toast-animation-type: #{$animation-name}-left;
        }

        &.dxbl-toast-align-right > .dxbl-toast {
            --dxbl-toast-animation-type: #{$animation-name}-right;
        }

        &.dxbl-toast-align-center {
            &.dxbl-toast-align-top > .dxbl-toast {
                --dxbl-toast-animation-type: #{$animation-name}-down;
            }

            &.dxbl-toast-align-bottom > .dxbl-toast {
                --dxbl-toast-animation-type: #{$animation-name}-up;
            }
        }
    }

    &.dxbl-toast-animation-slide {
        @include toast-direction-animation("dxbl-toast-animation-slide-in");
    }

    &.dxbl-toast-animation-fade-in > .dxbl-toast {
        --dxbl-toast-animation-type: dxbl-toast-animation-fade-in;
    }

    &.dxbl-toast-animation-slide-fade-in {
        @include toast-direction-animation("dxbl-toast-animation-fade-in");
    }
}
