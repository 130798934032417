/* stylelint-disable-next-line selector-class-pattern */
.dxbrv-wrapper {
    --dxbrv-primary-color: #{$dx-primary};
    --dxbrv-secondary-color: #{$dx-secondary};
    --dxbrv-light-color: #{$dx-light};
    --dxbrv-dark-color: #{$dx-dark};
    --dxbrv-white-color: #{$dx-white};
    --dxbrv-gray-color: #888;
    --dxbrv-danger-color: #{$dx-danger};
    --dxbrv-group-bg-color: #{$dx-group-bg};
    --dxbrv-border-color: #{$dx-border-color};
}
