@import "mixins/button.standalone";

.dxbl-btn-standalone {
    --dxbl-btn-box-shadow: #{getButtonBoxShadow($dx-button-type-contained, "none", $dx-state-normal)};
    --dxbl-btn-disabled-opacity: 1;
    --dxbl-btn-link-hover-color: #{getButtonColor($dx-button-type-contained, "link", $dx-state-hover, "text")};
    --dxbl-btn-link-text-decoration: #{getLinkButtonTextDecoration()};

    @if $dx-theme {
        --dxbl-btn-bg: #{getButtonColor($dx-button-type-contained, "none", $dx-state-normal, "bg")};
        --dxbl-btn-color: #{getButtonColor($dx-button-type-contained, "none", $dx-state-normal, "text")};
        --dxbl-btn-border-color: #{getButtonColor($dx-button-type-contained, "none", $dx-state-normal, "border")};

        @include write-button-color-if-different("--dxbl-btn-active-bg", $dx-button-type-contained, "none", $dx-state-active, "bg");
        @include write-button-color-if-different("--dxbl-btn-active-color", $dx-button-type-contained, "none", $dx-state-active, "text");
        @include write-button-color-if-different("--dxbl-btn-active-border-color", $dx-button-type-contained, "none", $dx-state-active, "border");
        @include write-button-color-if-different("--dxbl-btn-hover-bg", $dx-button-type-contained, "none", $dx-state-hover, "bg");
        @include write-button-color-if-different("--dxbl-btn-hover-color", $dx-button-type-contained, "none", $dx-state-hover, "text");
        @include write-button-color-if-different("--dxbl-btn-hover-border-color", $dx-button-type-contained, "none", $dx-state-hover, "border");
        @include write-button-color-if-different("--dxbl-btn-disabled-bg", $dx-button-type-contained, "none", $dx-state-disabled, "bg");
        @include write-button-color-if-different("--dxbl-btn-disabled-color", $dx-button-type-contained, "none", $dx-state-disabled, "text");
        @include write-button-color-if-different("--dxbl-btn-disabled-border-color", $dx-button-type-contained, "none", $dx-state-disabled, "border");
        @include write-button-color-if-different("--dxbl-btn-focus-bg", $dx-button-type-contained, "none", $dx-state-focused, "bg");
        @include write-button-color-if-different("--dxbl-btn-focus-color", $dx-button-type-contained, "none", $dx-state-focused, "text");
        @include write-button-color-if-different("--dxbl-btn-focus-border-bg", $dx-button-type-contained, "none", $dx-state-focused, "border");

        &.dxbl-btn-outline-none {
            @include button-standalone-outline-style("none", $dx-primary);
        }

        &.dxbl-btn-text-none {
            @include button-standalone-text-style("none", $dx-primary);
        }
    }

    @else {
        --dxbl-btn-caption-disabled-opacity: #{getButtonOpacity($dx-button-type-contained, "none", $dx-state-disabled, "text")};
        --dxbl-btn-icon-disabled-opacity: #{getButtonOpacity($dx-button-type-contained, "none", $dx-state-disabled, "icon")};
    }

    @include button-sizes($dx-button-type-contained, "none");
    @include button-standalone-focus-style($dx-button-type-contained, "none", $dx-primary);

    @each $render-style, $value in $dx-theme-colors {
        &.dxbl-btn-#{$render-style} {
            @include button-standalone-style($render-style, $value);
        }

        &.dxbl-btn-outline-#{$render-style} {
            @include button-standalone-outline-style($render-style, $value);
        }

        &.dxbl-btn-text-#{$render-style} {
            @include button-standalone-text-style($render-style, $value);
        }
    }
}
