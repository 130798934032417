@keyframes dxbl-skeleton-opacity-animation {
    0% {
        opacity: 0.05;
    }

    50% {
        opacity: 0.15;
    }

    100% {
        opacity: 0.05;
    }
}

.dxbl-invisible {
    visibility: hidden !important; // stylelint-disable-line declaration-no-important
}

.dxbl-visible {
    visibility: visible !important; // stylelint-disable-line declaration-no-important
}

.dxbl-invisible-content {
    & > * {
        visibility: hidden !important; // stylelint-disable-line declaration-no-important
    }
}

.dxbl-invisible-off-screen {
    visibility: hidden !important; // stylelint-disable-line declaration-no-important
    transform: translateX(-100000px) !important; // stylelint-disable-line declaration-no-important
}

.dxbl-visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
}

.dxbl-display-none {
    display: none !important; // stylelint-disable-line declaration-no-important
}

.dxbl-align-center {
    text-align: center;
}

.dxbl-align-left {
    text-align: left;
}

.dxbl-align-right {
    text-align: right;
}

.dxbl-nowrap {
    white-space: nowrap !important; // stylelint-disable-line declaration-no-important
}

.dxbl-prevent-safari-scrolling {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.dxbl-preventsel {
    user-select: none;
}

.dxbl-flex-column {
    flex-direction: column !important; // stylelint-disable-line declaration-no-important
}

.dxbl-flex-row {
    flex-direction: row !important; // stylelint-disable-line declaration-no-important
}

.dxbl-flex-nowrap {
    flex-wrap: nowrap !important; // stylelint-disable-line declaration-no-important
}

.dxbl-skeleton-animate .dxbl-skeleton-item > div {
    animation: 2s linear infinite forwards dxbl-skeleton-opacity-animation;
}

.dxbl-skeleton-item {
    $skeleton-indexes: 1 3 0 2 4;

    > div {
        height: 100%;
        border-radius: 10rem;
        background-color: currentcolor;
        opacity: 0.1;
    }

    &.dxbl-align-right > div {
        margin-left: auto;
        margin-right: 0;
    }

    &.dxbl-align-center > div {
        margin-left: auto;
        margin-right: auto;
    }

    > .dxbl-skeleton-date {
        max-width: 8rem;
    }

    > .dxbl-skeleton-bool {
        max-width: 4rem;
    }

    @for $i from 1 through length($skeleton-indexes) {
        > .dxbl-skeleton-string-#{$i - 1} {
            width: (4 + nth($skeleton-indexes, $i)) * 10%;
        }

        > .dxbl-skeleton-number-#{$i - 1} {
            max-width: #{(2 + nth($skeleton-indexes, $i))}em;
        }
    }
}

.dxbl-virtual-items-container-pattern {
    position: fixed;
    top: -10000px;
    left: -10000px;
    visibility: hidden;
}

.dxbl-a11y-status-helper {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
