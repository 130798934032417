.dxbl-popup-cell {
    position: absolute;
    top: 0;
    left: 0;
}

.dxbl-popup-text {
    font-size: inherit;
    line-height: inherit;
    padding-left: 0;
    padding-right: 0;
}
