@use "sass:math";

@function add-counter-reset($counter, $start: 1) {
    @if $counter > $start {
        @return add-counter-reset($counter - 1, $start) + " " + dxbl-list-#{$counter};
    }

    @return dxbl-list-#{$counter};
}

@mixin if-less-then-max-indent($value) {
    @if $value < $dx-html-editor-max-indent {
        li.ql-indent-#{$value} {
            counter-reset: add-counter-reset($dx-html-editor-max-indent, $value + 1);
        }
    }
}

@mixin add-counter($counter) {
    @if $counter > 0 {
        @include add-counter($counter - 1);

        ol li[data-list=ordered].ql-indent-#{$counter} {
            counter-increment: dxbl-list-#{$counter};

            &::before {
                content: counter(dxbl-list-#{$counter}, nth($dx-html-editor-list-style, ($counter % 3) + 1)) ". ";
            }
        }

        @include if-less-then-max-indent($counter);
    }
}

@mixin add-indent-styles($counter) {
    @if $counter > 0 {
        @include add-indent-styles($counter - 1);

        .ql-indent-#{$counter} {
            padding-inline-start: 3 * $counter * 1em;
        }

        li.ql-indent-#{$counter} {
            padding-inline-start: 3 * $counter + $dx-html-editor-list-inline-start;
        }
    }
}
