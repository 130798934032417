@use "sass:math";

@import "common";

@mixin button-style-size($type, $render-style, $size) {
    @include write-button-typography-if-different("--dxbl-btn-font-size", $type, $render-style, $size, "fontSize");
    @include write-button-typography-if-different("--dxbl-btn-line-height", $type, $render-style, $size, "lineHeight");
    @include write-button-composition-if-different("--dxbl-btn-padding-x", $type, $render-style, $dx-state-normal, $size, "horizontalPadding");
    @include write-button-composition-if-different("--dxbl-btn-padding-y", $type, $render-style, $dx-state-normal, $size, "verticalPadding");
    @include write-button-composition-if-different("--dxbl-btn-image-spacing", $type, $render-style, $dx-state-normal, $size, "itemSpacing");
    @include write-button-border-width-if-different("--dxbl-btn-border-width", $type, $render-style, $dx-state-normal, $size);
    @include write-button-border-radius-if-different("--dxbl-btn-border-radius", $type, $render-style, $dx-state-normal, $size);

    @if $render-style != "none" {
        &.dxbl-btn-icon-only {
            --dxbl-btn-padding-x: #{getIconButtonComposition($type, $render-style, $dx-state-normal, $size, "horizontalPadding")};
            --dxbl-btn-padding-y: #{getIconButtonComposition($type, $render-style, $dx-state-normal, $size, "verticalPadding")};
        }
    }
}

@mixin button-style-state-size($type, $render-style, $state, $size) {
    $state-name: if($state == $dx-state-focused, "focus", $state);

    @if $dx-button-override-padding-for-states-enabled {
        @include write-button-composition-if-different("--dxbl-btn-#{$state-name}-padding-x", $type, $render-style, $state, $size, "horizontalPadding");
        @include write-button-composition-if-different("--dxbl-btn-#{$state-name}-padding-y", $type, $render-style, $state, $size, "verticalPadding");
    }

    @include write-button-composition-if-different("--dxbl-btn-#{$state-name}-image-spacing", $type, $render-style, $state, $size, "itemSpacing");

    @if $dx-button-override-border-width-for-states-enabled {
        @include write-button-border-width-if-different("--dxbl-btn-#{$state-name}-border-width", $type, $render-style, $state, $size);
    }

    @if $dx-button-override-border-radius-for-states-enabled {
        @include write-button-border-radius-if-different("--dxbl-btn-#{$state-name}-border-radius", $type, $render-style, $state, $size);
    }

    @if $dx-button-override-padding-for-states-enabled and $render-style != "none" {
        &.dxbl-btn-icon-only {
            --dxbl-btn-#{$state-name}-padding-x: #{getIconButtonComposition($type, $render-style, $state, $size, "horizontalPadding")};
            --dxbl-btn-#{$state-name}-padding-y: #{getIconButtonComposition($type, $render-style, $state, $size, "verticalPadding")};
        }
    }
}

@mixin button-state-sizes($type, $render-style, $state) {
    @include button-style-state-size($type, $render-style, $state, $dx-size-medium);

    &.dxbl-sm {
        @include button-style-state-size($type, $render-style, $state, $dx-size-small);
    }

    &.dxbl-lg {
        @include button-style-state-size($type, $render-style, $state, $dx-size-large);
    }
}

@mixin button-sizes($type, $render-style) {
    @include button-style-size($type, $render-style, $dx-size-medium);

    &.dxbl-sm {
        @include button-style-size($type, $render-style, $dx-size-small);
    }

    &.dxbl-lg {
        @include button-style-size($type, $render-style, $dx-size-large);
    }
}

@mixin button-standalone-style($render-style, $color) {
    @include write-button-box-shadow-if-different("--dxbl-btn-box-shadow", $dx-button-type-contained, $render-style, $dx-state-normal);

    @if $dx-theme {
        @include write-button-color-if-different("--dxbl-btn-color", $dx-button-type-contained, $render-style, $dx-state-normal, "text");
        @include write-button-color-if-different("--dxbl-btn-bg", $dx-button-type-contained, $render-style, $dx-state-normal, "bg");
        @include write-button-color-if-different("--dxbl-btn-border-color", $dx-button-type-contained, $render-style, $dx-state-normal, "border");
    }

    @else {
        --dxbl-btn-color: #{get-external-theme-base-fore-color($render-style)};
        --dxbl-btn-bg: #{$color};
        --dxbl-btn-border-color: #{$color};
    }

    @include button-sizes($dx-button-type-contained, $render-style);
    @include button-standalone-active-style($dx-button-type-contained, $render-style, $color);
    @include button-standalone-hover-style($dx-button-type-contained, $render-style, $color);
    @include button-standalone-disabled-style($dx-button-type-contained, $render-style, $color);
    @include button-standalone-focus-style($dx-button-type-contained, $render-style, $color);
}

@mixin button-standalone-outline-style($render-style, $color) {
    @include write-button-box-shadow-if-different("--dxbl-btn-box-shadow", $dx-button-type-outline, $render-style, $dx-state-normal);

    @if $dx-theme {
        @include write-button-color-if-different("--dxbl-btn-color", $dx-button-type-outline, $render-style, $dx-state-normal, "text");
        @include write-button-color-if-different("--dxbl-btn-border-color", $dx-button-type-outline, $render-style, $dx-state-normal, "border");
    }

    @else {
        --dxbl-btn-color: #{$color};
        --dxbl-btn-border-color: #{$color};
    }

    @include button-sizes($dx-button-type-outline, $render-style);
    @include button-standalone-active-style($dx-button-type-outline, $render-style, $color);
    @include button-standalone-hover-style($dx-button-type-outline, $render-style, $color);
    @include button-standalone-disabled-style($dx-button-type-outline, $render-style, $color, false, true);
    @include button-standalone-focus-style($dx-button-type-outline, $render-style, $color);
}

@mixin button-standalone-text-style($render-style, $color) {
    @include write-button-box-shadow-if-different("--dxbl-btn-box-shadow", $dx-button-type-text, $render-style, $dx-state-normal);

    @if $dx-theme {
        @include write-button-color-if-different("--dxbl-btn-color", $dx-button-type-text, $render-style, $dx-state-normal, "text");
    }

    @else {
        --dxbl-btn-color: #{$color};
    }

    @include button-sizes($dx-button-type-text, $render-style);
    @include button-standalone-active-style($dx-button-type-text, $render-style, $color);
    @include button-standalone-hover-style($dx-button-type-text, $render-style, $color);
    @include button-standalone-disabled-style($dx-button-type-text, $render-style, $color, false, false);
    @include button-standalone-focus-style($dx-button-type-text, $render-style, $color);
}

@mixin button-standalone-active-style($type, $render-style, $color) {
    @if $dx-button-override-box-shadow-for-states-enabled {
        @include write-button-box-shadow-if-different("--dxbl-btn-active-box-shadow", $type, $render-style, $dx-state-active);
    }

    @include button-state-sizes($type, $render-style, $dx-state-active);

    $bg-color-var: "--dxbl-btn-active-bg";
    $color-var: "--dxbl-btn-active-color";
    $border-color-var: "--dxbl-btn-active-border-color";

    @if $dx-theme {
        @include write-button-color-if-different($bg-color-var, $type, $render-style, $dx-state-active, "bg");
        @include write-button-color-if-different($color-var, $type, $render-style, $dx-state-active, "text");
        @include write-button-color-if-different($border-color-var, $type, $render-style, $dx-state-active, "border");
    }

    @else if $render-style == "none" {
        #{$bg-color-var}: #{$color};

        @include write-button-color-if-different($color-var, $type, $render-style, $dx-state-active, "text");
        @include write-button-color-if-different($border-color-var, $type, $render-style, $dx-state-active, "border");
    }

    @else {
        @include contextual-state-style(
            $bg-color-var, $color-var, $border-color-var, "--dxbl-btn-active-background",
            $color, $render-style, $dx-btn-active-bg-tint-amount, $dx-btn-active-bg-shade-amount
        );
    }
}

@mixin button-standalone-hover-style($type, $render-style, $color) {
    @if $dx-button-override-box-shadow-for-states-enabled {
        @include write-button-box-shadow-if-different("--dxbl-btn-hover-box-shadow", $type, $render-style, $dx-state-hover);
    }

    @include button-state-sizes($type, $render-style, $dx-state-hover);

    $bg-color-var: "--dxbl-btn-hover-bg";
    $color-var: "--dxbl-btn-hover-color";
    $border-color-var: "--dxbl-btn-hover-border-color";

    @if $dx-theme {
        @include write-button-color-if-different($bg-color-var, $type, $render-style, $dx-state-hover, "bg");
        @include write-button-color-if-different($color-var, $type, $render-style, $dx-state-hover, "text");
        @include write-button-color-if-different($border-color-var, $type, $render-style, $dx-state-hover, "border");
    }

    @else {
        @include contextual-state-style(
            $bg-color-var, $color-var, $border-color-var, "--dxbl-btn-hover-background",
            $color, $render-style, $dx-btn-active-bg-tint-amount, $dx-btn-active-bg-shade-amount
        );
    }
}

@mixin button-standalone-disabled-style($type, $render-style, $color, $needBackground: true, $needBorder: true) {
    @if $dx-button-override-box-shadow-for-states-enabled {
        @include write-button-box-shadow-if-different("--dxbl-btn-disabled-box-shadow", $type, $render-style, $dx-state-disabled);
    }

    @include button-state-sizes($type, $render-style, $dx-state-disabled);

    $bg-color-var: "--dxbl-btn-disabled-bg";
    $color-var: "--dxbl-btn-disabled-color";
    $border-color-var: "--dxbl-btn-disabled-border-color";

    @if $dx-theme {
        @include write-button-color-if-different($bg-color-var, $type, $render-style, $dx-state-disabled, "bg");
        @include write-button-color-if-different($color-var, $type, $render-style, $dx-state-disabled, "text");
        @include write-button-color-if-different($border-color-var, $type, $render-style, $dx-state-disabled, "border");
    }

    @else {
        --dxbl-btn-caption-disabled-opacity: #{getButtonOpacity($type, $render-style, $dx-state-disabled, "text")};
        --dxbl-btn-icon-disabled-opacity: #{getButtonOpacity($type, $render-style, $dx-state-disabled, "icon")};

        @include contextual-disabled-style(
            $bg-color-var, $color-var, $border-color-var, "--dxbl-btn-disabled-background", null,
            $color, $render-style, $dx-btn-disabled-bg-tint-amount, $dx-btn-disabled-bg-shade-amount,
            $needBackground, $needBorder
        );
    }
}

@mixin button-standalone-focus-style($type, $render-style, $color) {
    --dxbl-btn-focus-outline-size: #{getButtonBorderWidth($type, $render-style, $dx-state-focused, $dx-size-medium, false)};
    --dxbl-btn-focus-outline-offset: #{getButtonComposition($type, $render-style, $dx-state-focused, $dx-size-medium, "horizontalPadding", false)};
    --dxbl-btn-focus-box-shadow: #{getButtonBoxShadow($type, $render-style, $dx-state-focused)};

    &.dxbl-sm {
        @include write-button-border-width-if-different("--dxbl-btn-focus-outline-size", $type, $render-style, $dx-state-focused, $dx-size-small, false);
        @include write-button-composition-if-different("--dxbl-btn-focus-outline-offset", $type, $render-style, $dx-state-focused, $dx-size-small, "horizontalPadding", false);
    }

    &.dxbl-lg {
        @include write-button-border-width-if-different("--dxbl-btn-focus-outline-size", $type, $render-style, $dx-state-focused, $dx-size-large, false);
        @include write-button-composition-if-different("--dxbl-btn-focus-outline-offset", $type, $render-style, $dx-state-focused, $dx-size-large, "horizontalPadding", false);
    }

    @include button-state-sizes($type, $render-style, $dx-state-focused);

    @if $dx-theme {
        @include write-button-color-if-different("--dxbl-btn-focus-bg", $type, $render-style, $dx-state-focused, "bg");
        @include write-button-color-if-different("--dxbl-btn-focus-color", $type, $render-style, $dx-state-focused, "text");
        @include write-button-color-if-different("--dxbl-btn-focus-border-bg", $type, $render-style, $dx-state-focused, "border");
        @include write-button-color-if-different("--dxbl-btn-focus-outline-color", $type, $render-style, $dx-state-focused, "frame");
    }

    @else {
        --dxbl-btn-focus-color: #{$color};
        --dxbl-btn-focus-border-bg: #{$color};
        --dxbl-btn-focus-outline-color: #{$dx-primary};
    }
}
