@import "common";

@mixin menu-item-pressed-state($bg-color-var, $color-var) {
    @include element-calculated-style($bg-color-var, $color-var, $dx-navigation-item-selected-bg, $dx-context-menu-item-active-base-bg, $dx-context-menu-item-active-bg-alpha);
}

@mixin menu-items-separator($width-var, $style-var, $color-var, $isHorizontal: true) {
    margin: 0;

    @if $isHorizontal {
        height: 0;
        border-top: var(#{$width-var}) var(#{$style-var}) var(#{$color-var});
    }

    @else {
        width: 0;
        border-left: var(#{$width-var}) var(#{$style-var}) var(#{$color-var});
    }

    &:first-child {
        display: none;
    }
}
