@import "common";
@import "navigation";
@import "context-menu";

@mixin menu-item-selected-state($selected-bg-var, $selected-color-var, $selected-hover-bg-var, $selected-hover-color-var) {
    @include not-hover-state() {
        @include state-selected-style($selected-bg-var, $selected-color-var);
    }

    @include hover-state() {
        @include state-selected-hover-style($selected-hover-bg-var, $selected-hover-color-var);
    }
}

@mixin menu-item-state($hover-bg-var, $hover-color-var, $selected-bg-var, $selected-color-var, $selected-hover-bg-var, $selected-hover-color-var) {
    @include selected-state() {
        &:not(.dxbl-menu-item-mute-selection) {
            @include menu-item-selected-state($selected-bg-var, $selected-color-var, $selected-hover-bg-var, $selected-hover-color-var);
        }
    }

    @include active-state() {
        @include menu-item-selected-state($selected-bg-var, $selected-color-var, $selected-hover-bg-var, $selected-hover-color-var);
    }

    @include hover-state() {
        @include not-selected-state() {
            @include not-active-state() {
                @include navigation-item-hover-state($hover-bg-var, $hover-color-var);
            }
        }
    }
}

%menu-item-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    position: relative;
}

%menu-item {
    display: flex;
    width: 100%;
    height: 100%;
}

@mixin menu-item-btn($font-family-var, $font-size-var, $line-height-var, $color-var, $padding-x-var, $padding-y-var, $image-spacing-var,
    $disabled-bg-var, $disabled-color-var, $disabled-opacity-var,
    $hover-bg-var, $hover-color-var, $selected-bg-var, $selected-color-var,
    $selected-hover-bg-var, $selected-hover-color-var) {
    $property-values: (
        "--dxbl-btn-font-family": $font-family-var,
        "--dxbl-btn-font-size": $font-size-var,
        "--dxbl-btn-line-height": $line-height-var,
        "--dxbl-btn-padding-x": $padding-x-var,
        "--dxbl-btn-padding-y": $padding-y-var,
        "--dxbl-btn-image-spacing": $image-spacing-var,
        "--dxbl-btn-color": $color-var,
        "--dxbl-btn-disabled-bg": $disabled-bg-var,
        "--dxbl-btn-disabled-color": $disabled-color-var,
        "--dxbl-btn-disabled-opacity": $disabled-opacity-var
    );

    @each $property, $value in $property-values {
        @if $value {
            #{$property}: var(#{$value});
        }
    }

    --dxbl-btn-border-radius: unset;
    --dxbl-btn-border-width: unset;
    --dxbl-btn-focus-box-shadow: none;

    flex: 1 1 auto;
    text-decoration: none;
    text-align: start;
    transform: scale(1);
    cursor: pointer;
    border: inherit;

    @include embedded-image-color(
        "--dxbl-btn-image-color",
        "--dxbl-btn-disabled-image-color",
        "--dxbl-btn-hover-image-color",
        "--dxbl-btn-active-image-color",
        "--dxbl-btn-active-hover-image-color"
    );
    @include menu-item-state($hover-bg-var, $hover-color-var, $selected-bg-var, $selected-color-var, $selected-hover-bg-var, $selected-hover-color-var);
}

@mixin menu-item-image-placeholder($property-name, $image-size-var, $image-space-var, $indent-count-var: null, $ratio: 1) {
    --dxbl-menu-item-image-placeholder-width: calc(var(#{$image-size-var}) + var(#{$image-space-var}));

    @if not $indent-count-var {
        #{$property-name}: calc(var(--dxbl-menu-item-image-placeholder-width) * $ratio);
    }

    @else {
        #{$property-name}: calc(var(#{$indent-count-var}, 0) * var(--dxbl-menu-item-image-placeholder-width) * $ratio);
    }
}
