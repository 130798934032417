@import "mixins/menu";

.dxbl-menu.dxbl-menu-horizontal {
    --dxbl-menu-item-font-size: #{$dx-menu-item-font-size};
    --dxbl-menu-item-line-height: #{$dx-menu-item-line-height};
    --dxbl-menu-item-padding-x: #{$dx-menu-item-padding-x};
    --dxbl-menu-item-padding-y: #{$dx-menu-item-padding-y};
    --dxbl-menu-title-padding-x: #{$dx-menu-title-padding-x};
    --dxbl-menu-title-padding-y: #{$dx-menu-title-padding-y};
    --dxbl-menu-item-image-spacing: #{$dx-menu-item-image-spacing};
    --dxbl-menu-hamburger-btn-padding-x: #{$dx-menu-hamburger-btn-padding-x};
    --dxbl-menu-hamburger-btn-padding-y: #{$dx-menu-hamburger-btn-padding-y};
    --dxbl-menu-hamburger-btn-hover-color: #{$dx-menu-item-hover-color};
    --dxbl-menu-hamburger-btn-hover-bg: #{$dx-menu-item-hover-bg};
    --dxbl-menu-hamburger-btn-selected-bg: #{$dx-menu-item-selected-bg};
    --dxbl-menu-hamburger-btn-selected-color: #{$dx-menu-item-selected-color};
    --dxbl-menu-hamburger-btn-selected-hover-bg: #{$dx-menu-item-selected-hover-bg};
    --dxbl-menu-hamburger-btn-selected-hover-color: #{$dx-menu-item-selected-hover-color};
    --dxbl-menu-item-focus-outline-color: #{$dx-menu-item-focus-outline-color};
    --dxbl-menu-item-focus-outline-width: #{$dx-menu-item-focus-outline-width};
    --dxbl-menu-item-focus-outline-offset: #{$dx-menu-item-focus-outline-offset};

    &.dxbl-sm {
        --dxbl-menu-item-font-size: #{$dx-menu-item-font-size-sm};
        --dxbl-menu-item-line-height: #{$dx-menu-item-line-height-sm};
        --dxbl-menu-item-padding-x: #{$dx-menu-item-padding-x-sm};
        --dxbl-menu-item-padding-y: #{$dx-menu-item-padding-y-sm};
        --dxbl-menu-title-padding-x: #{$dx-menu-title-padding-x-sm};
        --dxbl-menu-title-padding-y: #{$dx-menu-title-padding-y-sm};
        --dxbl-menu-item-image-spacing: #{$dx-menu-item-image-spacing-sm};
        --dxbl-menu-hamburger-btn-padding-x: #{$dx-menu-hamburger-btn-padding-x-sm};
        --dxbl-menu-hamburger-btn-padding-y: #{$dx-menu-hamburger-btn-padding-y-sm};
    }

    &.dxbl-lg {
        --dxbl-menu-item-font-size: #{$dx-menu-item-font-size-lg};
        --dxbl-menu-item-line-height: #{$dx-menu-item-line-height-lg};
        --dxbl-menu-item-padding-x: #{$dx-menu-item-padding-x-lg};
        --dxbl-menu-item-padding-y: #{$dx-menu-item-padding-y-lg};
        --dxbl-menu-title-padding-x: #{$dx-menu-title-padding-x-lg};
        --dxbl-menu-title-padding-y: #{$dx-menu-title-padding-y-lg};
        --dxbl-menu-item-image-spacing: #{$dx-menu-item-image-spacing-lg};
        --dxbl-menu-hamburger-btn-padding-x: #{$dx-menu-hamburger-btn-padding-x-lg};
        --dxbl-menu-hamburger-btn-padding-y: #{$dx-menu-hamburger-btn-padding-y-lg};
    }

    &:not([data-dx-focus-hidden]) {
        & .dxbl-menu-nav > .dxbl-menu-nav-bar > .dxbl-btn {
            --dxbl-btn-focus-outline-color: var(--dxbl-menu-item-focus-outline-color);
            --dxbl-btn-focus-outline-size: var(--dxbl-menu-item-focus-outline-width);
            --dxbl-btn-focus-outline-offset: var(--dxbl-menu-item-focus-outline-offset);
        }
    }

    .dxbl-menu-title {
        padding: var(--dxbl-menu-title-padding-y) var(--dxbl-menu-title-padding-x);
    }

    & > .dxbl-menu-bar {
        & > .dxbl-menu-item-list {
            flex-wrap: wrap;

            & > .dxbl-menu-list-item-stretch {
                :not(:last-child).dxbl-menu-item-text-container {
                    margin-right: auto;
                }
            }

            &:not(.dxbl-menu-with-title) {
                & > .dxbl-menu-list-item:first-child.dxbl-menu-item-pos-start > .dxbl-menu-item > .dxbl-btn,
                & > .dxbl-menu-list-item:first-child.dxbl-menu-list-item-stretch > .dxbl-menu-item > .dxbl-btn {
                    border-top-left-radius: var(--dxbl-menu-top-left-border-radius);
                    border-bottom-left-radius: var(--dxbl-menu-bottom-left-border-radius);
                }
            }

            & > .dxbl-menu-list-item:last-child.dxbl-menu-item-pos-end > .dxbl-menu-item > .dxbl-btn,
            & > .dxbl-menu-list-item:last-child.dxbl-menu-list-item-stretch > .dxbl-menu-item > .dxbl-btn {
                border-top-right-radius: var(--dxbl-menu-top-right-border-radius);
                border-bottom-right-radius: var(--dxbl-menu-bottom-right-border-radius);
            }

            & > .dxbl-menu-separator {
                @include menu-items-separator("--dxbl-menu-separator-width", "--dxbl-menu-separator-style", "--dxbl-menu-separator-color", false);
            }
        }
    }

    & > .dxbl-menu-nav {
        position: relative;

        .dxbl-menu-nav-bar {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &.dxbl-menu-hamburger-btn-pos-left {
                & > .dxbl-btn {
                    border-top-left-radius: var(--dxbl-menu-top-left-border-radius);
                    border-bottom-left-radius: var(--dxbl-menu-bottom-left-border-radius);
                }

                justify-content: flex-end;
                flex-direction: row-reverse;
            }

            &.dxbl-menu-hamburger-btn-pos-right {
                & > .dxbl-btn {
                    border-top-right-radius: var(--dxbl-menu-top-right-border-radius);
                    border-bottom-right-radius: var(--dxbl-menu-bottom-right-border-radius);
                }

                & > .dxbl-menu-title {
                    margin-right: auto;
                }

                justify-content: flex-end;
            }

            &.dxbl-menu-hamburger-btn-pos-reverse {
                & > .dxbl-btn {
                    border-top-left-radius: var(--dxbl-menu-top-left-border-radius);
                    border-bottom-left-radius: var(--dxbl-menu-bottom-left-border-radius);
                }

                & > .dxbl-menu-title {
                    margin-left: auto;
                }

                flex-direction: row-reverse;
                justify-content: flex-end;
            }

            & > .dxbl-menu-title {
                align-self: center;
            }

            & > .dxbl-btn {
                @include menu-item-btn(
                    null, null, null,
                    "--dxbl-menu-color",
                    "--dxbl-menu-hamburger-btn-padding-x",
                    "--dxbl-menu-hamburger-btn-padding-y",
                    null, null, null, null,
                    "--dxbl-menu-hamburger-btn-hover-bg",
                    "--dxbl-menu-hamburger-btn-hover-color",
                    "--dxbl-menu-hamburger-btn-selected-bg",
                    "--dxbl-menu-hamburger-btn-selected-color",
                    "--dxbl-menu-hamburger-btn-selected-hover-bg",
                    "--dxbl-menu-hamburger-btn-selected-hover-color"
                );

                flex: initial;

                & > .dxbl-menu-hamburger {
                    margin: 0;
                }
            }
        }
    }
}
