.dxbl-upload {
    --dxbl-upload-load-state-font-size: #{$dx-upload-load-state-font-size};
    --dxbl-upload-load-state-color: #{$dx-upload-load-state-color};
    --dxbl-upload-image-spacing: #{$dx-upload-image-spacing};
    --dxbl-upload-file-size-color: #{$dx-upload-file-size-color};
    --dxbl-upload-file-size-padding-left: #{$dx-upload-file-size-padding-left};
    --dxbl-upload-file-btn-padding: #{$dx-upload-file-btn-padding};
    --dxbl-upload-file-font-weight: #{$dx-upload-file-font-weight};
    --dxbl-upload-file-height: #{$dx-upload-file-height};
    --dxbl-upload-file-list-view-padding-top: #{$dx-upload-file-list-view-padding-top};
    --dxbl-upload-file-margin-bottom: #{$dx-upload-file-margin-bottom};
    --dxbl-upload-padding-top: #{$dx-upload-padding-top};
    --dxbl-upload-max-file-warning-border-radius: #{$dx-upload-max-file-warning-border-radius};
    --dxbl-upload-max-file-warning-icon-margin: #{$dx-upload-max-file-warning-icon-margin};
    --dxbl-upload-max-file-warning-message-margin: #{$dx-upload-max-file-warning-message-margin};

    padding-top: var(--dxbl-upload-padding-top);

    .dxbl-upload-container {
        display: flex;
        flex-flow: column nowrap;

        .dxbl-upload-panel {
            text-wrap: nowrap;
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            flex-flow: row nowrap;
            gap: 1rem;
            height: fit-content;

            .dxbl-upload-button-panel {
                display: inline-flex;
                justify-content: flex-end;
                gap: 0.5rem;
                flex-wrap: nowrap;
                margin-left: auto;
            }
        }

        .dxbl-upload-file-list-view {
            display: flex;
            flex-direction: column;
            padding-top: var(--dxbl-upload-file-list-view-padding-top);

            .dxbl-upload-file-view {
                display: flex;
                flex-direction: row;
                margin-bottom: var(--dxbl-upload-file-margin-bottom);
                height: var(--dxbl-upload-file-height);

                .dxbl-btn {
                    padding: var(--dxbl-upload-file-btn-padding);
                    height: fit-content;
                    cursor: pointer;

                    .dxbl-image {
                        display: inline-block;
                        align-self: center;
                        margin: 0;
                    }

                    &.dxbl-upload-file-btn {
                        --dxbl-upload-file-btn-padding: #{$dx-upload-file-icon-padding};

                        pointer-events: none;
                    }
                }

                .dxbl-upload-file-view-icon-container {
                    padding-left: 1rem;
                    display: flex;
                    position: relative;
                    z-index: 0;
                }

                .dxbl-upload-file-view-details {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                    min-width: 0;

                    .dxbl-upload-file-view-info {
                        display: inline-flex;
                        width: 100%;
                        flex-direction: row;
                        justify-content: flex-start;

                        .dxbl-upload-file-view-text {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            font-weight: var(--dxbl-upload-file-font-weight);
                        }

                        .dxbl-upload-file-view-progress-bar-value {
                            margin-left: auto;
                        }
                    }

                    .dxbl-upload-file-view-load-state {
                        font-size: var(--dxbl-upload-load-state-font-size);
                        color: var(--dxbl-upload-load-state-color);

                        &.dxbl-upload-file-view-invalid-load-state {
                            --dxbl-upload-load-state-color: #{$dx-validation-invalid-color};
                        }

                        &.dxbl-upload-file-view-success-load-state {
                            --dxbl-upload-load-state-color: #{$dx-validation-valid-color};
                        }
                    }

                    .dxbl-upload-file-view-size {
                        padding-left: var(--dxbl-upload-file-size-padding-left);
                        color: var(--dxbl-upload-file-size-color);
                        white-space: nowrap;
                    }
                }
            }
        }

        .dxbl-upload-max-file-warning {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #{$dx-warning};
            border-radius: var(--dxbl-upload-max-file-warning-border-radius);
            padding: 0 0.5rem;
            margin-top: 1rem;
            width: 100%;

            & > .dxbl-upload-max-file-warning-message {
                display: flex;
                justify-content: center;
                flex: 1;
                margin: var(--dxbl-upload-max-file-warning-message-margin);
            }

            & > .dxbl-image {
                align-self: flex-start;
                margin: var(--dxbl-upload-max-file-warning-icon-margin);
            }

            & > .dxbl-btn {
                align-self: flex-start;
                margin: var(--dxbl-upload-max-file-warning-message-margin) 0;

                & > .dxbl-image {
                    margin: 0;
                }

                padding: 0;
                border: 0;
            }
        }
    }

    &.dxbl-upload-full-width {
        width: 100%;
    }

    &.dxbl-upload-hide {
        display: none;
    }
}

@media (max-width: 474px) {
    .dxbl-upload .dxbl-upload-button-panel .dxbl-btn-caption {
        display: none;
    }
}
