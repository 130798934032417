/* stylelint-disable-next-line selector-class-pattern */
.dxreRoot {
    --dxre-primary-color: #{$dx-primary};
    --dxre-view-background-color: #{$dx-rich-edit-view-bg};
    --dxre-background-color: #{$dx-rich-edit-bg};
    --dxre-border-width: #{$dx-rich-edit-border-width};
    --dxre-border-radius: #{$dx-rich-edit-border-radius};
    --dxre-quick-search-border-radius: #{$dx-rich-edit-quick-search-border-radius};
    --dxre-border-color: #{$dx-rich-edit-border-color};
    --dxre-bg-color: #{$dx-rich-edit-bg};
}
