@use "sass:map";

$dx-button-type-contained: "contained";
$dx-button-type-outline: "outline";
$dx-button-type-text: "text";

$dx-state-normal: "normal";
$dx-state-focused: "focused";
$dx-state-disabled: "disabled";
$dx-state-hover: "hover";
$dx-state-active: "active";

$dx-size-small: "small";
$dx-size-medium: "medium";
$dx-size-large: "large";

@function dxGet($map, $list...) {
    $result: $map;

    @each $key in $list {
        @if not $result {
            @error "#{$list} isn't valid key, failed on #{$key}";
        }
        /* stylelint-disable-next-line scss/no-duplicate-dollar-variables */
        $result: map.get($result, $key);
    }

    @return $result;
}

@mixin write-if-different($css-var, $value, $etalonValue, $normalStateValue: null, $currentStateEtalonValue: null) {
    @if $css-var and ($value != $etalonValue or ($normalStateValue != null and $value != $normalStateValue)
        or ($currentStateEtalonValue != null and $value != $currentStateEtalonValue and $currentStateEtalonValue != $etalonValue)) {
        #{$css-var}: #{$value};
    }
}

@import "button.map-helper";
