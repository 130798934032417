@import "common";

@mixin tab-selected-element($selected-element-bg, $selected-element-height) {
    @if $selected-element-height > 0 {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            z-index: 0;
            background-color: #{$selected-element-bg};
        }
    }
}

@mixin active-tab-selected-element($selected-element-height, $border-width, $position) {
    @if $selected-element-height > 0 {
        &::after {
            @if $position == top or $position == bottom {
                height: #{$selected-element-height};
                left: 0;
                right: 0;
            }

            @else {
                width: #{$selected-element-height};
                top: -#{$border-width};
                bottom: -#{$border-width};
            }

            @if $position == top {
                margin-bottom: -#{$border-width};
                bottom: 0;
            }

            @else if $position == bottom {
                margin-top: -#{$border-width};
                top: 0;
            }

            @else if $position == left {
                margin-right: -#{$border-width};
                right: 0;
            }

            @else {
                margin-left: -#{$border-width};
                left: 0;
            }
        }
    }
}

@function mirror-position($position) {
    @if $position == left {
        @return right;
    }

    @else if $position == right {
        @return left;
    }

    @else if $position == top {
        @return bottom;
    }

    @else if $position == bottom {
        @return top;
    }

    @error("Unknown position #{$position}");
}

@mixin border-radius($position, $rad) {
    @if $position == left or $position == right {
        top-#{$position}-radius: #{$rad};
        bottom-#{$position}-radius: #{$rad};
    }

    @else if $position == top or $position == bottom {
        #{$position}-left-radius: #{$rad};
        #{$position}-right-radius: #{$rad};
    }

    @else {
        @error("Unknown position #{$position}");
    }
}
