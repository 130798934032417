dxbl-bar-gauge {
    --dxbl-bar-gauge-title-font-color: #{$dx-title-font-color};
    --dxbl-bar-gauge-subtitle-font-color: #{$dx-title-font-color};
    --dxbl-bar-gauge-legend-title-font-color: #{$dx-title-font-color};
    --dxbl-bar-gauge-legend-subtitle-font-color: #{$dx-title-font-color};
    --dxbl-bar-gauge-legend-item-font-color: #{$dx-legend-item-font-color};

    display: flex;
    position: relative;

    .dxbl-widget-container {
        width: 100%;
        height: 100%;
    }
}
