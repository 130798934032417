%layout-root {
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
    display: grid;
}

.dxbl-gridlayout {
    width: 100%;
    height: 100%;
    display: block;

    & > .dxbl-gridlayout-root {
        @extend %layout-root;
    }
}

.dxbl-stacklayout {
    display: block;

    & > .dxbl-stacklayout-root {
        @extend %layout-root;
    }
}
