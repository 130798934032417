.dxbl-grid-dragging-target {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1999;
}

.dxbl-grid-move-cursor,
.dxbl-grid-move-cursor a:hover,
.dxbl-grid-move-cursor .dxbl-grid-action,
.dxbl-grid-move-cursor * {
    cursor: move !important; // stylelint-disable-line declaration-no-important
}

.dxbl-resize-cursor,
.dxbl-resize-cursor .dxbl-grid th.dxbl-grid-action {
    cursor: col-resize;
}

.dxbl-grid-action {
    cursor: pointer;
}

.dxbl-virtual-items-container-pattern {
    &.dxbl-grid-virtual-items-container-pattern > div {
        border-width: var(--dxbl-grid-border-width) 0 0 var(--dxbl-grid-border-width);
        border-color: var(--dxbl-grid-border-color);
        border-style: var(--dxbl-grid-border-style);
        color: var(--dxbl-grid-color);
        line-height: var(--dxbl-grid-line-height);
        padding: var(--dxbl-grid-text-cell-padding-y) var(--dxbl-grid-text-cell-padding-x);
    }
}

.dxbl-grid-edit-canceled-notification {
    white-space: nowrap;
    margin: 10px;

    & > svg {
        width: 24px;
        height: 24px;
        color: #{$dx-danger};
    }

    & > span {
        margin-left: 10px;
        color: #{$dx-color};
    }
}
