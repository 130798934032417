.dxbl-badge {
    --dxbl-navigation-badge-font-size: #{$dx-navigation-badge-font-size};
    --dxbl-navigation-badge-font-weight: #{$dx-navigation-badge-font-weight};
    --dxbl-navigation-badge-line-height: #{$dx-navigation-badge-line-height};
    --dxbl-navigation-badge-color: #{$dx-navigation-badge-color};
    --dxbl-navigation-badge-bg: #{$dx-navigation-badge-secondary-bg};
    --dxbl-navigation-badge-border-radius: #{$dx-navigation-badge-border-radius};
    --dxbl-navigation-badge-spacing: #{$dx-navigation-badge-spacing};
    --dxbl-navigation-badge-text-spacing-x: #{$dx-navigation-badge-text-spacing-x};

    display: flex;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    font-size: var(--dxbl-navigation-badge-font-size);
    font-weight: var(--dxbl-navigation-badge-font-weight);
    line-height: var(--dxbl-navigation-badge-line-height);
    color: var(--dxbl-navigation-badge-color);
    border-radius: var(--dxbl-navigation-badge-border-radius);
    background-color: var(--dxbl-navigation-badge-bg);
    margin: 0 var(--dxbl-navigation-badge-spacing);

    &.dxbl-text {
        --dxbl-text-padding-x: var(--dxbl-navigation-badge-text-spacing-x);
        --dxbl-text-padding-y: 0;
    }
}

.dxbl-sm .dxbl-badge {
    --dxbl-navigation-badge-font-size: #{$dx-navigation-badge-font-size-sm};
    --dxbl-navigation-badge-line-height: #{$dx-navigation-badge-line-height-sm};
    --dxbl-navigation-badge-spacing: #{$dx-navigation-badge-spacing-sm};
    --dxbl-navigation-badge-text-spacing-x: #{$dx-navigation-badge-text-spacing-x-sm};
}

.dxbl-lg .dxbl-badge {
    --dxbl-navigation-badge-font-size: #{$dx-navigation-badge-font-size-lg};
    --dxbl-navigation-badge-line-height: #{$dx-navigation-badge-line-height-lg};
    --dxbl-navigation-badge-spacing: #{$dx-navigation-badge-spacing-lg};
    --dxbl-navigation-badge-text-spacing-x: #{$dx-navigation-badge-text-spacing-x-lg};
}

.dxbl-active .dxbl-badge {
    --dxbl-navigation-badge-color: #{$dx-navigation-badge-selected-color};
    --dxbl-navigation-badge-bg: #{$dx-navigation-badge-secondary-selected-bg};
}
