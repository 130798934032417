@use "sass:math";
$iconSize: inherit !default;
$iconLineWidth: 2px !default;
$offsetNFull: -$iconLineWidth;
$halfOffset: math.div($iconLineWidth, 2);
$halfNOffset: -$halfOffset;
@mixin register-icons($icons) {
    #{$icons} {
        width: $iconSize;
        height: $iconSize;
        display: inline-flex;
        flex: 0 0 100%;
        position: relative;
    }
}
@mixin border($borders...) {
    @each $borderType in $borders {
        #{$borderType}: $iconLineWidth solid currentColor;
    }
}
@mixin backdrop($width: 100%, $height: 100%, $left: 0, $top: 0) {
    content: " ";
    position: absolute;
    left: $left;
    top: $top;
    width: $width;
    height: $height;
}
@mixin caretLeft($offset: 0px) {
    @include backdrop(80%, 80%);
    @include border(border-top, border-left);
    transform: translate($offset, $offset);
}
@include register-icons((".dxbs-icon-close", ".dxbs-icon-apply",
    ".dxbs-caret-left", ".dxbs-caret-right",
    ".dxbs-icon-expanded", ".dxbs-icon-collapsed", ".dxbs-caret-bottom"));

.dxbs-caret-left,
.dxbs-caret-right {
    height: 10px;
    width: 10px;
}

.dxbs-icon-close:before,
.dxbs-icon-close:after {
    @include backdrop(calc(141% - 2px), 0, -7%, 50%);
    @include border(border-top);
}
.dxbs-icon-close:before {
    transform: rotate(45deg) translate($halfNOffset, math.div($halfNOffset, 2));
}
.dxbs-icon-close:after {
    transform: rotate(-45deg) translate(math.div($halfOffset, 2), $halfNOffset);
}
.dxbs-icon-apply:before {
    @include backdrop(100%, 50%, 0, 12.5%);
    @include border(border-bottom, border-left);
    transform: rotate(-45deg) translate($halfNOffset, $halfNOffset);
}

.dxbs-caret-left::before,
.dxbs-caret-right:before,
.dxbs-caret-bottom::before {
    @include caretLeft($iconLineWidth);
}
.dxbs-caret-left {
    transform: rotate(-45deg);
}
.dxbs-caret-right {
    transform: rotate(135deg);
}
.dxbs-caret-bottom {
    transform: rotate(-135deg);
}
.dxbs-icon-expanded::before,
.dxbs-icon-collapsed::before {
    content: " ";
    position: absolute;
    left: 50%;
    top: 50%;
    border-top: .3rem solid transparent;
    border-left: .3rem solid currentColor;
    border-bottom: .3rem solid transparent;
    transform: translate(-30%, -50%);
}
.dxbs-icon-expanded::before {
    transform: rotate(90deg) translate(-85%, 25%);
}
.dxbs-icon-expanded,
.dxbs-icon-collapsed {
    overflow: hidden;
}
