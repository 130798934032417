@import "../../bootstrap-compile/mixins/buttons";

@mixin primary-button-custom() {
    $shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($primary, $primary, 15%), .5);
    .btn-primary {
        @include button-focused-shadow($shadow);
    }
}

@mixin button-shadow($color, $button-main-color, $btn-focus-width) {
    $shadow: 0 0 0 $btn-focus-width rgba(mix($color, $button-main-color, 15%), .5);
    @include button-focused-shadow($shadow);
}
