@keyframes dxbl-progress-bar-indeterminate-horizontal {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}

@keyframes dxbl-progress-bar-indeterminate-vertical {
    0% {
        transform: translateY(100%) scaleY(0.5);
    }

    80% {
        transform: translateY(0) scaleY(0.4);
    }

    100% {
        transform: translateY(0) scaleY(0);
    }
}

@keyframes dxbl-progress-bar-indeterminate-circular {
    from { transform: rotate(-360deg); }
    to { transform: rotate(360deg); }
}

.dxbl-progress-bar {
    --dxbl-progress-bar-thickness: #{$dx-progress-bar-thickness};
    --dxbl-progress-bar-indicator-color: #{$dx-progress-bar-color};
    --dxbl-progress-bar-track-height: 100%;
    --dxbl-progress-bar-track-width: 100%;
    --dxbl-progress-bar-border-radius: #{$dx-progress-bar-border-radius};
    --dxbl-progress-bar-label-space: #{$dx-progress-bar-label-space};
    --dxbl-progress-bar-label-gap: #{$dx-progress-bar-label-gap};
    --dxbl-progress-bar-label-line-height: #{$dx-progress-bar-label-line-height};
    --dxbl-progress-bar-label-font-size: #{$dx-progress-bar-label-font-size};
    --dxbl-progress-bar-indeterminate-animation-length: #{$dx-progress-bar-indeterminate-animation-length};
    --dxbl-progress-bar-icon-width: #{$dx-image-size};
    --dxbl-progress-bar-track-color: #{$dx-progress-bar-track-color};
    --dxbl-progress-bar-track-opacity: #{$dx-progress-bar-track-opacity};
    --dxbl-progress-bar-indeterminate-animation: unset;
    --dxbl-progress-bar-indeterminate-height: unset;
    --dxbl-progress-bar-indeterminate-width: unset;
    --dxbl-progress-bar-indeterminate-transform-origin: unset;

    display: flex;
    visibility: hidden;

    &.dxbl-sm {
        --dxbl-progress-bar-thickness: #{$dx-progress-bar-thickness-sm};
        --dxbl-progress-bar-border-radius: #{$dx-progress-bar-border-radius-sm};
        --dxbl-progress-bar-label-space: #{$dx-progress-bar-label-space-sm};
        --dxbl-progress-bar-label-gap: #{$dx-progress-bar-label-gap-sm};
        --dxbl-progress-bar-label-line-height: #{$dx-progress-bar-label-line-height-sm};
        --dxbl-progress-bar-label-font-size: #{$dx-progress-bar-label-font-size-sm};
    }

    &.dxbl-lg {
        --dxbl-progress-bar-thickness: #{$dx-progress-bar-thickness-lg};
        --dxbl-progress-bar-border-radius: #{$dx-progress-bar-border-radius-lg};
        --dxbl-progress-bar-label-space: #{$dx-progress-bar-label-space-lg};
        --dxbl-progress-bar-label-gap: #{$dx-progress-bar-label-gap-lg};
        --dxbl-progress-bar-label-line-height: #{$dx-progress-bar-label-line-height-lg};
        --dxbl-progress-bar-label-font-size: #{$dx-progress-bar-label-font-size-lg};
    }

    &.dxbl-progress-bar-success {
        --dxbl-progress-bar-indicator-color: #{$dx-progress-bar-success-color};

        &.dxbl-progress-bar-horizontal .dxbl-progress-bar-indicator {
            width: 100%;
        }

        &.dxbl-progress-bar-vertical .dxbl-progress-bar-indicator {
            height: 100%;
        }
    }

    &.dxbl-progress-bar-error {
        --dxbl-progress-bar-indicator-color: #{$dx-progress-bar-error-color};

        &.dxbl-progress-bar-horizontal .dxbl-progress-bar-indicator {
            width: 100%;
        }

        &.dxbl-progress-bar-vertical .dxbl-progress-bar-indicator {
            height: 100%;
        }
    }

    &.dxbl-progress-bar-pause {
        --dxbl-progress-bar-indicator-color: #{$dx-progress-bar-pause-color};
        --dxbl-progress-bar-track-color: #{$dx-progress-bar-pause-color};
        --dxbl-progress-bar-track-opacity: #{$dx-progress-bar-track-opacity};
    }

    &.dxbl-progress-bar-indeterminate {
        .dxbl-progress-bar-track-container {
            overflow: hidden;
        }

        &.dxbl-progress-bar-success,
        &.dxbl-progress-bar-error {
            .dxbl-progress-bar-indicator {
                animation: unset;
            }
        }

        .dxbl-progress-bar-track-container > .dxbl-progress-bar-indicator {
            width: 100%;
            height: 100%;
            animation: var(--dxbl-progress-bar-indeterminate-animation);
            transform-origin: var(--dxbl-progress-bar-indeterminate-transform-origin);
        }
    }


    &.dxbl-progress-bar-horizontal {
        --dxbl-progress-bar-track-height: var(--dxbl-progress-bar-thickness);
        --dxbl-progress-bar-track-width: 100%;
        --dxbl-progress-bar-indeterminate-width: 100%;
        --dxbl-progress-bar-indeterminate-animation: dxbl-progress-bar-indeterminate-horizontal var(--dxbl-progress-bar-indeterminate-animation-length) linear infinite;
        --dxbl-progress-bar-indeterminate-transform-origin: 0 50%;
    }

    &.dxbl-progress-bar-vertical {
        --dxbl-progress-bar-track-height: 100%;
        --dxbl-progress-bar-track-width: var(--dxbl-progress-bar-thickness);
        --dxbl-progress-bar-indeterminate-height: 100%;
        --dxbl-progress-bar-indeterminate-animation: dxbl-progress-bar-indeterminate-vertical var(--dxbl-progress-bar-indeterminate-animation-length) linear infinite;
        --dxbl-progress-bar-indeterminate-transform-origin: 0 0;

        display: inline-flex;

        .dxbl-progress-bar-indicator {
            bottom: 0;
        }
    }

    &.dxbl-progress-circular-bar {
        display: flex;

        .dxbl-progress-bar-container {
            position: relative;
        }

        &.dxbl-progress-bar-indeterminate {
            .dxbl-progress-bar-circle {
                height: 100%;
                animation: dxbl-progress-bar-indeterminate-circular var(--dxbl-progress-bar-indeterminate-animation-length) linear infinite;
            }
        }

        .dxbl-progress-circular-bar-container {
            position: relative;
            width: 100%;
            height: 100%;

            .dxbl-progress-bar-circle {
                transform: rotate(-90deg);
            }

            .dxbl-progress-circular-bar-track {
                fill: none;
                stroke-width: var(--dxbl-progress-bar-thickness);
                stroke: var(--dxbl-progress-bar-track-color);
                opacity: var(--dxbl-progress-bar-track-opacity);
            }

            .dxbl-progress-circular-bar-indicator {
                fill: none;
                stroke-width: var(--dxbl-progress-bar-thickness);
                stroke-linecap: round;
                stroke: var(--dxbl-progress-bar-indicator-color);
            }
        }
    }

    .dxbl-progress-bar-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        &.dxbl-progress-bar-row {
            flex-direction: row;

            .dxbl-progress-bar-label.dxbl-progress-bar-label-before {
                margin-right: var(--dxbl-progress-bar-label-space);
            }

            .dxbl-progress-bar-label.dxbl-progress-bar-label-after {
                margin-left: var(--dxbl-progress-bar-label-space);
            }
        }

        &.dxbl-progress-bar-column {
            flex-direction: column;

            .dxbl-progress-bar-label.dxbl-progress-bar-label-before {
                margin-bottom: var(--dxbl-progress-bar-label-space);
            }

            .dxbl-progress-bar-label.dxbl-progress-bar-label-after {
                margin-top: var(--dxbl-progress-bar-label-space);
            }
        }
    }

    .dxbl-progress-bar-track-container {
        order: 2;
        position: relative;
        height: var(--dxbl-progress-bar-track-height);
        width: var(--dxbl-progress-bar-track-width);
        border-radius: var(--dxbl-progress-bar-border-radius);

        .dxbl-progress-bar-track {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: var(--dxbl-progress-bar-track-color);
            opacity: var(--dxbl-progress-bar-track-opacity);
        }

        .dxbl-progress-bar-indicator {
            position: absolute;
            background-color: var(--dxbl-progress-bar-indicator-color);
            border-radius: var(--dxbl-progress-bar-border-radius);
            width: var(--dxbl-progress-bar-indicator-width);
            height: var(--dxbl-progress-bar-indicator-height);
        }
    }

    .dxbl-progress-bar-label {
        display: flex;
        justify-content: center;

        &.dxbl-progress-bar-no-icon {
            --dxbl-progress-bar-icon-width: 0px; // stylelint-disable-line length-zero-no-unit
        }

        &.dxbl-progress-bar-label-hidden {
            display: none;
        }

        &.dxbl-progress-circular-bar-label {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;

            .dxbl-progress-bar-label-container {
                display: flex;
                align-items: center;
                gap: var(--dxbl-progress-bar-label-gap);

                & > p {
                    --dxbl-progress-bar-label-width: calc(var(--dxbl-progress-bar-width) - var(--dxbl-progress-bar-icon-width) - var(--dxbl-progress-bar-label-gap) - var(--dxbl-progress-bar-thickness) * 2 - 1rem); // stylelint-disable-line  max-line-length

                    margin-bottom: 0;
                    line-height: var(--dxbl-progress-bar-label-line-height);
                    font-size: var(--dxbl-progress-bar-label-font-size);
                    max-width: var(--dxbl-progress-bar-label-width);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .dxbl-progress-bar-label-container {
            display: flex;
            align-items: center;
            gap: var(--dxbl-progress-bar-label-gap);

            & > p {
                --dxbl-progress-bar-label-width: calc(var(--dxbl-progress-bar-width) - var(--dxbl-progress-bar-icon-width) - var(--dxbl-progress-bar-label-gap) - var(--dxbl-progress-bar-thickness) * 2 - 1rem); // stylelint-disable-line  max-line-length

                line-height: var(--dxbl-progress-bar-label-line-height);
                font-size: var(--dxbl-progress-bar-label-font-size);
                max-width: var(--dxbl-progress-bar-width);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 0;
            }

            & > svg {
                fill: var(--dxbl-progress-bar-indicator-color);
            }
        }

        &.dxbl-progress-bar-label-before {
            order: 1;
        }

        &.dxbl-progress-bar-label-after {
            order: 3;
        }
    }
}
