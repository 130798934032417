@import "common";

@mixin list-box-item-focused-style() {
    &.dxbl-list-box-item-focused,
    &:focus {
        outline: #{$dx-list-box-focus-frame-size} solid #{$dx-list-box-focus-frame-color};
        outline-offset: calc(-1 * #{$dx-list-box-focus-frame-size});

        .dxSafari & { // stylelint-disable-line selector-class-pattern
            outline-offset: -0.12rem;
        }
    }
}

@mixin list-box-item-focused-state() {
    &.dxbl-list-box-item-focused,
    &:focus {
        @content;
    }
}

@mixin list-box-item-not-focused-state() {
    &:not(.dxbl-list-box-item-focused):not(:focus) {
        @content;
    }
}

@mixin list-box-row-state-hover-style($bg-color-var, $color-var, $border-width-var: null) {
    @include list-box-row-calculated-style($bg-color-var, $color-var, $dx-state-hover-bg,
        $dx-state-base-bg, $dx-state-hover-bg-alpha);
}

@mixin list-box-row-state-selected-style($bg-color-var, $color-var, $border-width-var: null) {
    @include list-box-row-calculated-style($bg-color-var, $color-var, $dx-state-selected-bg,
        $dx-state-base-bg, $dx-state-selected-bg-alpha);
}

@mixin list-box-row-state-selected-primary-style($bg-color-var, $color-var, $border-width-var: null) {
    @include list-box-row-calculated-style($bg-color-var, $color-var, $dx-state-selected-primary-bg,
        $dx-state-primary-bg, $dx-state-selected-primary-bg-alpha);
}

@mixin list-box-row-state-selected-primary-hover-style($bg-color-var, $color-var, $border-width-var: null) {
    @include list-box-row-calculated-style($bg-color-var, $color-var, $dx-state-selected-primary-hover-bg,
        $dx-state-primary-bg, $dx-state-selected-primary-hover-bg-alpha);
}

@mixin list-box-row-state-selected-hover-style($bg-color-var, $color-var, $border-width-var: null, $root-bg-color-var: null) {
    @include list-box-row-calculated-style($bg-color-var, $color-var, $dx-state-selected-hover-bg,
        $dx-state-base-bg, $dx-state-selected-hover-bg-alpha);
}

@mixin list-box-row-calculated-style($bg-color-var, $color-var, $bg-color, $base-bg-color, $alpha) {
    html:not(.dxSafari) & { // stylelint-disable-line selector-class-pattern
        position: relative;

        > td {
            background-color: var(#{$bg-color-var}, #{$bg-color});
            color: var(#{$color-var});

            &:first-of-type {
                @if not $bg-color or $bg-color == unset {
                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: inherit;
                        background-color: #{$base-bg-color};
                        opacity: #{$alpha};
                    }
                }
            }
        }
    }

    .dxSafari & { // stylelint-disable-line selector-class-pattern
        > td {
            @include element-calculated-style($bg-color-var, $color-var, $bg-color, $base-bg-color, $alpha);
        }
    }
}
