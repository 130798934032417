.dxbl-dropdown {
    &.dxbl-sm {
        &.dxbl-grid-filter-menu-dropdown-root {
            --dxbl-grid-filter-menu-dropdown-min-width: #{$dx-grid-filter-menu-dropdown-min-width-sm};
            --dxbl-grid-filter-menu-dropdown-min-height: #{$dx-grid-filter-menu-dropdown-min-height-sm};
            --dxbl-grid-filter-menu-dropdown-width: #{$dx-grid-filter-menu-dropdown-width-sm};
            --dxbl-grid-filter-menu-dropdown-height: #{$dx-grid-filter-menu-dropdown-height-sm};

            @media (max-width: map-get($dx-window-breakpoints, "sm")) {
                --dxbl-grid-filter-menu-dropdown-width: #{$dx-grid-filter-menu-dropdown-width-mobile-sm};
                --dxbl-grid-filter-menu-dropdown-min-width: #{$dx-grid-filter-menu-dropdown-min-width-mobile-sm};
            }
        }

        & > .dxbl-grid-filter-menu-dropdown {
            --dxbl-grid-filter-menu-dropdown-list-box-height: #{$dx-grid-filter-menu-dropdown-list-box-height-sm};
            --dxbl-grid-filter-menu-dropdown-treeview-height: #{$dx-grid-filter-menu-dropdown-treeview-height-sm};
            --dxbl-grid-filter-menu-dropdown-loading-panel-height: #{$dx-grid-filter-menu-dropdown-loading-panel-height-sm};
            --dxbl-grid-filter-menu-values-view-select-all-check-padding-x: #{$dx-list-box-item-padding-x-sm};
            --dxbl-grid-filter-menu-values-view-select-all-check-padding-y: #{$dx-list-box-item-padding-y-sm};
            --dxbl-grid-filter-menu-dropdown-footer-btn-width: #{$dx-grid-filter-menu-dropdown-footer-btn-width-sm};
            --dxbl-grid-filter-menu-dropdown-footer-btn-space: #{$dx-grid-filter-menu-dropdown-footer-btn-space-sm};
            --dxbl-grid-filter-menu-dropdown-padding-y: #{$dx-grid-filter-menu-dropdown-padding-y-sm};
        }
    }

    &.dxbl-lg {
        &.dxbl-grid-filter-menu-dropdown-root {
            --dxbl-grid-filter-menu-dropdown-min-width: #{$dx-grid-filter-menu-dropdown-min-width-lg};
            --dxbl-grid-filter-menu-dropdown-min-height: #{$dx-grid-filter-menu-dropdown-min-height-lg};
            --dxbl-grid-filter-menu-dropdown-width: #{$dx-grid-filter-menu-dropdown-width-lg};
            --dxbl-grid-filter-menu-dropdown-height: #{$dx-grid-filter-menu-dropdown-height-lg};

            @media (max-width: map-get($dx-window-breakpoints, "sm")) {
                --dxbl-grid-filter-menu-dropdown-width: #{$dx-grid-filter-menu-dropdown-width-mobile-lg};
                --dxbl-grid-filter-menu-dropdown-min-width: #{$dx-grid-filter-menu-dropdown-min-width-mobile-lg};
            }
        }

        & > .dxbl-grid-filter-menu-dropdown {
            --dxbl-grid-filter-menu-dropdown-list-box-height: #{$dx-grid-filter-menu-dropdown-list-box-height-lg};
            --dxbl-grid-filter-menu-dropdown-treeview-height: #{$dx-grid-filter-menu-dropdown-treeview-height-lg};
            --dxbl-grid-filter-menu-dropdown-loading-panel-height: #{$dx-grid-filter-menu-dropdown-loading-panel-height-lg};
            --dxbl-grid-filter-menu-values-view-select-all-check-padding-x: #{$dx-list-box-item-padding-x-lg};
            --dxbl-grid-filter-menu-values-view-select-all-check-padding-y: #{$dx-list-box-item-padding-y-lg};
            --dxbl-grid-filter-menu-dropdown-footer-btn-width: #{$dx-grid-filter-menu-dropdown-footer-btn-width-lg};
            --dxbl-grid-filter-menu-dropdown-footer-btn-space: #{$dx-grid-filter-menu-dropdown-footer-btn-space-lg};
            --dxbl-grid-filter-menu-dropdown-padding-y: #{$dx-grid-filter-menu-dropdown-padding-y-lg};
        }
    }
}

.dxbl-grid-filter-menu-dropdown-root {
    --dxbl-grid-filter-menu-dropdown-max-width: #{$dx-grid-filter-menu-dropdown-max-width};
    --dxbl-grid-filter-menu-dropdown-max-height: #{$dx-grid-filter-menu-dropdown-max-height};
    --dxbl-grid-filter-menu-dropdown-min-width: #{$dx-grid-filter-menu-dropdown-min-width};
    --dxbl-grid-filter-menu-dropdown-min-height: #{$dx-grid-filter-menu-dropdown-min-height};
    --dxbl-grid-filter-menu-dropdown-width: #{$dx-grid-filter-menu-dropdown-width};
    --dxbl-grid-filter-menu-dropdown-height: #{$dx-grid-filter-menu-dropdown-height};

    @media (max-height: map-get($dx-window-breakpoints, "md")) {
        --dxbl-grid-filter-menu-dropdown-max-height: #{$dx-grid-filter-menu-dropdown-max-height-mobile};
    }

    @media (max-height: map-get($dx-window-breakpoints, "sm")) {
        --dxbl-grid-filter-menu-dropdown-max-height: #{$dx-grid-filter-menu-dropdown-max-height-mobile-sm};
    }

    @media (max-width: map-get($dx-window-breakpoints, "sm")) {
        --dxbl-grid-filter-menu-dropdown-width: #{$dx-grid-filter-menu-dropdown-width-mobile};
        --dxbl-grid-filter-menu-dropdown-min-width: #{$dx-grid-filter-menu-dropdown-min-width-mobile};
    }
}

.dxbl-grid-filter-menu-dropdown-custom-size {
    .dxbl-grid-filter-menu-dropdown {
        & > .dxbl-dropdown-body {
            --dxbl-grid-filter-menu-dropdown-list-box-height: fit-content;
            --dxbl-grid-filter-menu-dropdown-treeview-height: fit-content;
            --dxbl-grid-filter-menu-dropdown-loading-panel-height: 100%;

            & > .dxbl-grid-filter-menu-values-view {
                & > .dxbl-treeview {
                    display: contents;
                }
            }
        }
    }
}

.dxbl-grid-filter-menu-dropdown {
    --dxbl-dropdown-header-bg: var(--dxbl-dropdown-bg);
    --dxbl-dropdown-footer-bg: var(--dxbl-dropdown-bg);
    --dxbl-grid-filter-menu-dropdown-list-box-height: #{$dx-grid-filter-menu-dropdown-list-box-height};
    --dxbl-grid-filter-menu-dropdown-treeview-height: #{$dx-grid-filter-menu-dropdown-treeview-height};
    --dxbl-grid-filter-menu-dropdown-loading-panel-height: #{$dx-grid-filter-menu-dropdown-loading-panel-height};
    --dxbl-grid-filter-menu-values-view-select-all-check-padding-x: #{$dx-list-box-item-padding-x};
    --dxbl-grid-filter-menu-values-view-select-all-check-padding-y: #{$dx-list-box-item-padding-y};
    --dxbl-grid-filter-menu-dropdown-footer-btn-width: #{$dx-grid-filter-menu-dropdown-footer-btn-width};
    --dxbl-grid-filter-menu-dropdown-footer-btn-space: #{$dx-grid-filter-menu-dropdown-footer-btn-space};
    --dxbl-grid-filter-menu-dropdown-padding-y: #{$dx-grid-filter-menu-dropdown-padding-y};

    & > .dxbl-dropdown-body {
        --dxbl-dropdown-body-padding-y: var(--dxbl-grid-filter-menu-dropdown-padding-y);
        --dxbl-dropdown-body-align-items: stretch;
        --dxbl-dropdown-body-overflow-y: hidden;

        & > .dxbl-grid-filter-menu-values-view {
            display: contents;

            & > .dxbl-checkbox {
                padding: var(--dxbl-grid-filter-menu-values-view-select-all-check-padding-y) calc(var(--dxbl-grid-filter-menu-values-view-select-all-check-padding-x) + var(--dxbl-checkbox-border-width)) 0; // stylelint-disable-line max-line-length
                border-left: none;
                border-right: none;
                border-bottom-color: #{$dx-list-box-border-color};
            }

            & > .dxbl-listbox,
            & > .dxbl-list-box {
                height: var(--dxbl-grid-filter-menu-dropdown-list-box-height);
                border: none;
                overflow-x: hidden;
            }

            & > .dxbl-treeview {
                --dxbl-treeview-spacing-x: 0;

                height: var(--dxbl-grid-filter-menu-dropdown-treeview-height);
            }

            & > .dxbl-loading-panel {
                height: var(--dxbl-grid-filter-menu-dropdown-loading-panel-height);
                width: 100%;
            }
        }
    }

    & > .dxbl-dropdown-header,
    & > .dxbl-dropdown-footer {
        --dxbl-dropdown-border-style: none;

        display: flex;
    }

    & > .dxbl-dropdown-header {
        --dxbl-dropdown-header-padding-top: var(--dxbl-grid-filter-menu-dropdown-padding-y);
        --dxbl-dropdown-header-padding-bottom: 0;

        justify-content: space-between;
        align-items: center;
    }

    & > .dxbl-dropdown-footer {
        --dxbl-dropdown-footer-padding-top: 0;
        --dxbl-dropdown-footer-padding-bottom: var(--dxbl-grid-filter-menu-dropdown-padding-y);
        --dxbl-dropdown-footer-flex-wrap: nowrap;

        & > .dxbl-btn {
            flex-basis: var(--dxbl-grid-filter-menu-dropdown-footer-btn-width);

            &:last-child {
                margin-left: var(--dxbl-grid-filter-menu-dropdown-footer-btn-space);
            }
        }
    }
}
